import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Typography } from "antd";

import {
  MailOutlined,
  MessageTwoTone,
  HomeTwoTone,
} from "@ant-design/icons";

import { useIntl } from "react-intl";
import CardWithIcon from "../../components/CardWithIcon";

export default function Home(props) {
  const intl = useIntl();

  const authUser = useSelector((state) => state.auth.authUser);

  const cards = [
    {
      icon: <HomeTwoTone />,
      title: intl.formatMessage({ id: "global.contractor" }),
      description: "Manage the Contractor",
      link: "/app/stores",
    },
    {
      icon: <MessageTwoTone />,
      title: intl.formatMessage({ id: "global.fleet_owner" }),
      description: "Manage your Fleet owner",
      link: "/app/fleetowner",
    },
    {
      icon: <MessageTwoTone />,
      title: intl.formatMessage({ id: "global.owner_operator" }),
      description: "Manage your Owner Operator",
      link: "/app/owneroperator",
    },

    // {
    //   icon: <ContactsTwoTone />,
    //   title: intl.formatMessage({ id: "global.customers" }),
    //   description: "Manage your customers",
    //   link: "/app/customers",
    // },

    // {
    //   icon: <ContactsTwoTone />,
    //   title: intl.formatMessage({ id: "global.drivers" }),
    //   description: "Truckers",
    //   link: "/app/drivers",
    // },

    // {
    //   icon: <DollarTwoTone />,
    //   title: intl.formatMessage({ id: "global.invoices" }),
    //   description: "Invoices",
    //   link: "/app/invoices",
    // },
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Col span={18}>
          <Typography.Title level={4}>
            Hi, {authUser.firstname} {authUser.lastname}
          </Typography.Title>
          <div>
            <Typography.Text type="secondary">
              <MailOutlined /> {authUser.email}
            </Typography.Text>
          </div>
        </Col>
      </Col>


      <Col span={24}>
        <Col md={24} sm={24}>
          <Row gutter={[16, 24]}>
            {cards.map((card, index) => (
              <Col span={8} key={index}>
                <CardWithIcon
                  link={card.link}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={3} sm={0}></Col>
      </Col>
    </Row>
  );
}
