import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import config from "../../config";
import GoogleMapReact from "google-map-react";
import driversApi from "../../api/drivers";
import Pusher from "pusher-js";
const markerStyle = {
  height: "50px",
  width: "50px",
  marginTop: "-50px",
};

const imgStyle = {
  height: "100%",
};

const Marker = ({ title }) => (
  <div style={markerStyle}>
    <img
      style={imgStyle}
      src="https://cdn-icons-png.flaticon.com/512/6643/6643396.png"
      alt={title}
    />
    <h3>{title}</h3>
  </div>
);

export default function MapModal(props) {
  const { open, onClose, order } = props;

  const [listdriver, setListDriver] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultProps = {
    center: {
      lat: 21.028511,
      lng: 105.804817,
    },
    zoom: 11,
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let res = await driversApi.getDriverByOrderId(order.id);
      setListDriver(res.map((item) => item.driver));
      setLoading(false);
    }
    fetchData();
  }, [order]);

  useEffect(() => {
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: "ap1",
    });

    const channel = pusher.subscribe("map-geofencing");
    channel.bind("location", (data) => {
      console.log("data", data.driver);

      driversApi.getDriverByOrderId(order.id).then((res) => {
        console.log("result", res);
        setListDriver(res.map((item) => item.driver));
      });
    });
  }, [order]);

  const handleCancel = () => {
    onClose();
  };

  if (loading) return <Skeleton active />;

  return (
    <Modal
      title="Map Modal"
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleCancel}
      width="70%"
    >
      <div style={{ height: "500px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.GOOGLE_MAP_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {listdriver.length
            ? listdriver.map((driver) => {
                return (
                  <Marker
                    key={driver.id}
                    lat={driver.lat}
                    lng={driver.long}
                    title={driver.firstname}
                  />
                );
              })
            : null}
        </GoogleMapReact>
        {/* <TestMap /> */}
      </div>
    </Modal>
  );
}
