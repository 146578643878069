import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Table, Modal, Row, Col, Space, Card } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import customersApi from "../../api/customers";
import moment from "moment-timezone";
import CustomersForm from "../customers/CustomersForm";
import Orders from "./Orders";
moment.tz.setDefault("US/Eastern");

Customers.propTypes = {
    items: PropTypes.array,
};
Customers.defaultProps = {
    items: [],
};

function Customers(props) {
    const { onClose, open, storeId } = props;

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(1);
    const [item, setItem] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [isJobOpen, setIsJobOpen] = useState(false)
    const [customerId, setCustomerId] = useState(null)

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
        filter_name: ["store_id"],
        filter_value: [storeId]
    });
    const [modal, setModal] = useState(false);

    const onChangeTable = async (
        pagination,
        filters,
        sorter,
        extra = { currentDataSource: [] }
    ) => {
        setFilter({
            ...filter,
            page: pagination.current,
            limit: pagination.pageSize,
            order_dir: getOrder(sorter.order),
            order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
        });
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let items = await customersApi.getAll(filter);
            setItems(items.data);
            setLoading(false);
            setDeleting(false);
            if (total !== items.total) setTotal(items.total);
        }
        fetchData();
    }, [filter, deleting, reload, storeId]);

    const onReload = () => {
        setReload((reload) => !reload);
    };

    const onOpenModal = (record = null) => {
        setModal(true);
        setItem(record);
        console.log(record);
    };

    const onCloseModal = () => {
        setModal(false);
    };
    const intl = useIntl();
    const onRemove = (data) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "global.delete_confirm" }),
            okText: intl.formatMessage({ id: "global.yes" }),
            onOk: async () => {
                await customersApi.delete(data.id)
                    .catch((error) => {
                        console.error(error);
                        Modal.error({
                            title: error.data.message,
                            okText: intl.formatMessage({ id: "global.close" }),
                        });
                        setLoading(false);
                    });
                setDeleting(true);
            },
            onCancel() { },
        });
    };

    const getOrder = (order) => {
        if (order === "ascend") return "ASC";
        if (order === "descend") return "DESC";
        return "DESC";
    };

    const handleOpenJobModal = (id) => {
        setIsJobOpen(true)
        setCustomerId(id)
    }

    const columns = [
        {
            title: <IntlMessages id="global.id" />,
            key: "id",
            dataIndex: "id",
            width: 20,
            render: (text, record) => {
                return record.id;
            },
        },
        {
            title: <IntlMessages id="global.fullname" />,
            key: "fullname",
            sorter: true,

            dataIndex: "fullname",
            render: (text, record) => {
                //return `${record.fullname}`;
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onOpenModal(record)}
                        shape="round"
                        icon={<EditOutlined />}
                    >
                        {record.fullname}
                    </Button>
                );
            },
        },


        {
            title: <IntlMessages id="global.email" />,
            key: "email",
            sorter: true,

            dataIndex: "email",
            render: (text, record) => {
                return record.email;
            },
        },

        {
            title: <IntlMessages id="global.phone" />,
            key: "mobile",
            sorter: true,

            dataIndex: "mobile",
            render: (text, record) => {
                return (
                    record.phone_code ?
                        `(${record.phone_code}) ${record.mobile}` : record.mobile
                );
            },
        },

        {
            title: <IntlMessages id="global.userId" />,
            key: "user",
            sorter: true,

            dataIndex: "user",
            render: (text, record) => {
                return (
                    record.user ?
                        record.user.id : null
                );
            },
        },
        {
            title: "",
            width: 100,
            align: true,
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => handleOpenJobModal(record.id)}
                    >
                        {`Jobs`}
                    </Button>
                );
            },
        },

        {
            title: "Delete",
            width: 100,
            align: true,
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onRemove(record)}
                        danger
                    >
                        <IntlMessages id="global.delete" />
                    </Button>
                );
            },
        },
    ];

    return (
        <Modal
            title="Users"
            visible={open}
            destroyOnClose={true}
            onCancel={() => onClose()}
            width="80%"
            onOk={() => onClose()}
        >
            <Card bordered={false} style={{ minHeight: "100%" }}>
                <Row justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col>
                    </Col>
                    <Col>
                        <ActionBar
                            isShowAddButton={true}
                            onAdd={onOpenModal}
                            onRefresh={() => onReload()}
                            isShowPublishButtons={false}
                            isShowCopyButton={false}
                            isShowDeleteButton={false}
                        ></ActionBar>
                    </Col>
                </Row>

                <Table
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    onChange={onChangeTable}
                    rowKey="id"
                    size="small"
                    loading={loading}
                    scroll={{
                        x: "max-content",
                    }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"],
                        total: total,
                        current: filter.page,
                        pageSize: filter.limit,
                    }}
                />
                {modal ? (
                    <CustomersForm
                        open={modal}
                        onClose={onCloseModal}
                        currentData={item}
                        onReload={onReload}
                    />
                ) : null}
                {isJobOpen ?
                    <Orders
                        open={isJobOpen}
                        onClose={() => setIsJobOpen(false)}
                        customerId={customerId}
                    />
                    : null
                }
            </Card>
        </Modal>
    );
}

export default Customers;
