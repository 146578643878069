import React, { useEffect, useState } from "react";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import carsApi from "../../api/cars";
import CarForm from "../cars/CarForm";
import moment from "moment-timezone";
import FilterBar from "../../components/FilterBar";
moment.tz.setDefault("US/Eastern");

function Trucks(props) {
    const { onClose, open, operatorId } = props;

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(1);
    const [item, setItem] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [condition, setCondition] = useState({});

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
        filter_name: ["operator_id"],
        filter_value: [operatorId]
    });
    const [modal, setModal] = useState(false);

    const trucSize = {
        "3": "Tri-Axle",
        "4": "Quad-Axle",
        "5": "Quint-Axle",
        "6": "Tandem-Axle"
    }

    const onChangeTable = async (
        pagination,
        filters,
        sorter,
        extra = { currentDataSource: [] }
    ) => {
        setFilter({
            ...filter,
            page: pagination.current,
            limit: pagination.pageSize,
            order_dir: getOrder(sorter.order),
            order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
        });
    };

    const onFilter = debounce(async (value, title, type) => {
        if (type === "search") {
            setFilter((filter) => {
                return { ...filter, keyword: value };
            });
        } else {
            const filter_obj = { ...condition, [title]: value };
            let filter_name = [];
            let filter_value = [];
            for (var key in filter_obj) {
                if (filter_obj[key]) {
                    filter_name.push(key);
                    filter_value.push(filter_obj[key]);
                }
            }
            setFilter((filter) => {
                return {
                    ...filter,
                    filter_name: filter_name,
                    filter_value: filter_value,
                };
            });
        }
    }, 300);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let items = await carsApi.getAll(filter);

            setItems(items.data);
            setLoading(false);
            setDeleting(false);
            if (total !== items.total) setTotal(items.total);
        }
        fetchData();
    }, [filter, deleting, reload, operatorId]);

    const onReload = () => {
        setReload((reload) => !reload);
    };

    const onOpenModal = (record = null) => {
        setModal(true);
        setItem(record);
    };

    const onCloseModal = () => {
        setModal(false);
    };
    const intl = useIntl();
    const onRemove = (data) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "global.delete_confirm" }),
            okText: intl.formatMessage({ id: "global.yes" }),
            onOk: async () => {
                await carsApi.delete(data.id).catch((error) => {
                    Modal.error({
                        title: error.data.message,
                        okText: intl.formatMessage({ id: "global.close" }),
                    });
                    setLoading(false);
                });
                setDeleting(true);
            },
            onCancel() { },
        });
    };

    const getOrder = (order) => {
        if (order === "ascend") return "ASC";
        if (order === "descend") return "DESC";
        return "DESC";
    };

    const [openImg, setOpenImg] = useState(false)
    const [img, setImg] = useState("")

    const showImg = (record) => {
        setOpenImg(true)
        setImg(record.cover_image)
    }

    const closeImg = () => {
        setOpenImg(false)
    }

    const columns = [
        {
            title: <IntlMessages id="global.id" />,
            key: "id",

            sorter: true,
            dataIndex: "id",
            render: (text, record) => {
                return record.id;
            },
        },
        {
            title: <IntlMessages id="global.cover_image" />,
            key: "cover_image",

            dataIndex: "cover_image",
            render: (text, record) => {
                return record.cover_image ? (
                    <img
                        onClick={() => showImg(record)}
                        src={record.cover_image}
                        width="30"
                        height="35"
                        alt=""
                    ></img>
                ) : (
                    ``
                );
            },
        },

        {
            title: <IntlMessages id="global.serial" />,
            key: "serial",

            dataIndex: "serial",
            render: (text, record, index) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onOpenModal(record)}
                        shape="round"
                        icon={<EditOutlined />}
                    >
                        {record.serial}
                    </Button>)
            },
        },

        {
            title: <IntlMessages id="global.truckSize" />,
            key: "capacity",

            dataIndex: "capacity",
            render: (text, record, index) => {
                return trucSize[record.capacity];
            },
        },
        {
            title: "Delete",
            width: 100,
            align: true,
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onRemove(record)}
                        danger
                        shape="round"
                        icon={<DeleteOutlined />}
                    ></Button>
                );
            },
        },
    ];

    return (
        <Modal
            title="Trucks"
            visible={open}
            destroyOnClose={true}
            onCancel={() => onClose()}
            width="80%"
            onOk={() => onClose()}
        >
            <Card bordered={false} style={{ minHeight: "100%" }}>
                <Row justify="space-between">
                    <Col>
                    </Col>

                    <Col>
                        <ActionBar
                            onRefresh={() => onReload()}
                            isShowAddButton={true}
                            onAdd={onOpenModal}
                            isShowPublishButtons={false}
                            isShowCopyButton={false}
                            isShowDeleteButton={false}
                        ></ActionBar>
                    </Col>
                </Row>
                <FilterBar

                    onFilter={onFilter}
                    data={[
                    ]}

                >
                </FilterBar>

                <Table
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    onChange={onChangeTable}
                    rowKey="id"
                    size="small"
                    loading={loading}
                    scroll={{
                        x: "max-content",
                    }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"],
                        total: total,
                        current: filter.page,
                        pageSize: filter.limit,
                    }}
                />
                {modal ? (
                    <CarForm
                        open={modal}
                        onClose={onCloseModal}
                        currentData={item}
                        onReload={onReload}
                    />
                ) : null}
                {openImg ?
                    <Modal
                        visible={openImg}
                        centered
                        destroyOnClose
                        footer={null}
                        onCancel={closeImg}
                        width={410}
                    >
                        <img
                            src={img}
                            width="400"
                            height="400"
                            alt="" />
                    </Modal> : null
                }
            </Card>
        </Modal>
    );
}

export default Trucks;
