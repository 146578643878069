import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import MainApp from './container/MainApp';
import { NotificationContainer } from 'react-notifications';
import 'antd/dist/antd.less';
import './assets/css/custom.css';
import 'react-notifications/lib/notifications.css';
import moment from 'moment';
import Wrapper from './lang';
import en_US from './lang/locales/en_US';

moment.locale('en');
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConfigProvider locale={en_US}>
          <BrowserRouter>
            <Wrapper>
              <MainApp />
            </Wrapper>
            <NotificationContainer />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    )
  }
}
