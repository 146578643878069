export default {
  PUSHER_APP_ID: "1508951",
  PUSHER_APP_KEY: "1eac913b2043d18601f4",
  PUSHER_SECRET_KEY: "5a5ecea7db1bae877f23",
  ONESIGNAL_APP_KEY: `59b6c51d-6896-4f13-b851-71ae03e62bbd`,
  CLIENT_URL: "https:ezbinadmin.netlify.app",
  // API_URL: "http://localhost:5001",
  // MEDIA_URL: "http://localhost:5001",
  MEDIA_URL: "https://test-api.ezbintrucking.com/",
  API_URL: "https://test-api.ezbintrucking.com/",
};
