import React from "react";
import IntlMessages from "../components/IntlMessage";

import {
  HomeOutlined,
  CarOutlined,
  ApartmentOutlined,
  BankOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  BellOutlined,
  AuditOutlined,
  ReconciliationOutlined
} from "@ant-design/icons";

const menu = [
  {
    path: "/app/home",
    name: <IntlMessages id="global.home" />,
    icon: <HomeOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/users",
    name: <IntlMessages id="global.users" />,
    icon: <UsergroupAddOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/notifications",
    name: <IntlMessages id="global.notification" />,
    icon: <BellOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/stores",
    name: <IntlMessages id="global.contractor" />,
    icon: <BankOutlined />,
    permission: null,
    children: null,
  },
  // {
  //   path: "/app/customers",
  //   name: <IntlMessages id="global.customers" />,
  //   icon: <BankOutlined />,
  //   permission: null,
  //   children: null,
  // },
  {
    path: "/app/orders",
    name: <IntlMessages id="global.jobs" />,
    icon: <ShoppingCartOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/invoices",
    name: <IntlMessages id="global.invoices" />,
    icon: <ReconciliationOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/fleetowner",
    name: <IntlMessages id="global.fleet_owner" />,
    icon: <CarOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/owneroperator",
    name: <IntlMessages id="global.owner_operator" />,
    icon: <CarOutlined />,
    permission: null,
    children: null,
  },
  {
    path: "/app/contract",
    name: <IntlMessages id="global.contract" />,
    icon: <AuditOutlined />,
    permission: null,
    children: null,
  },
  // {
  //   path: "/app/drivers",
  //   name: <IntlMessages id="global.drivers" />,
  //   icon: <IdcardOutlined />,
  //   permission: null,
  //   children: null,
  // },
  // {
  //   path: "/app/cars",
  //   name: <IntlMessages id="global.cars" />,
  //   icon: <CarOutlined />,
  //   permission: null,
  //   children: null,
  // },
  {
    path: "/app/subcribers",
    name: <IntlMessages id="global.subcribers" />,
    icon: <ApartmentOutlined />,
    permission: null,
    children: null,
  },

  // {
  //   path: "/app/sites",
  //   name: <IntlMessages id="global.sites" />,
  //   icon: <FileDoneOutlined />,
  //   permission: null,
  //   children: null,
  // },
];

export default menu;
