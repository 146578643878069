import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import IntlMessages from "../../components/IntlMessage";
import invoicesApi from "../../api/invoices";
import moment from "moment-timezone";
import Loads from "./Loads";
moment.tz.setDefault("US/Eastern");

const InvoicesItem = (props) => {
  const { open, onClose, invoice } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let result = await invoicesApi.getOperatorInvoiceItem(invoice.id);
      setItems(result);
      setLoading(false);
    }
    fetchData();
  }, [invoice]);

  const openLoad = (record) => {
    setModal(true);
    setItem(JSON.parse(record));
  };

  const closeLoad = () => {
    setModal(false);
  };

  const columns = [
    {
      title: <IntlMessages id="global.drivers" />,
      key: "name",

      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: <IntlMessages id="global.truck" />,
      key: "description",

      dataIndex: "description",
      render: (text, record) => {
        return record.description;
      },
    },
    {
      title: <IntlMessages id="global.total" />,
      key: "total",

      dataIndex: "total",
      render: (text, record) => {
        return `$${record.total}`;
      },
    },
    {
      title: <IntlMessages id="global.created" />,
      key: "created_at",

      dataIndex: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("MM-DD, hh:mm a");
      },
    },
    {
      title: <IntlMessages id="global.loads" />,
      key: "loads",
      dataIndex: "loads",
      render: (text, record) => {
        let arr = JSON.parse(record.loads);
        let total = 0;
        arr.map((item) => (total = total + Number(item.weight)));
        return (
          <Button
            disabled={!arr.length}
            type="primary"
            size="small"
            onClick={() => openLoad(record.loads)}
            danger
            shape="round"
          >
            {`${arr.length} (${total} tons)`}
          </Button>
        );
      },
    },
  ];
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal
      title={<IntlMessages id="global.invoice_details" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleCancel}
      width="70%"
    >
      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        rowKey="id"
        size="small"
        scroll={{
          x: "max-content",
        }}
        loading={loading}
        pagination={null}
      />
      {modal ? <Loads data={item} open={modal} onClose={closeLoad} /> : null}
    </Modal>
  );
};
export default InvoicesItem;
