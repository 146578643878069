import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Input, Button, Tabs, Radio } from "antd";
import IntlMessages from "../../components/IntlMessage";
import sitesApi from "../../api/sites";

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function SitesForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };

        if (currentData) {
          sitesApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        } else {
          sitesApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    email: currentData && currentData.email ? currentData.email : "",
    name: currentData && currentData.name ? currentData.name : "",
    meta_title:
      currentData && currentData.meta_title ? currentData.meta_title : "",
    meta_description:
      currentData && currentData.meta_description
        ? currentData.meta_description
        : "",
    currency: currentData && currentData.currency ? currentData.currency : "",
    timezone: currentData && currentData.timezone ? currentData.timezone : "",
    from_name:
      currentData && currentData.from_name ? currentData.from_name : "",
    from_email:
      currentData && currentData.from_email ? currentData.from_email : "",
    hotline: currentData && currentData.hotline ? currentData.hotline : "",
    locale: currentData && currentData.locale ? currentData.locale : "",
    status: currentData && currentData.status ? true : false,
  };

  console.log("currenData:", currentData);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.sites" />} key="1">
            <Form.Item
              label={<IntlMessages id="sites.email" />}
              name="email"
              rules={[
                {
                  type: "email",
                  message: <IntlMessages id="global.email_not_valid" />,
                },

                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.name" />}
              name="name"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="sites.meta_title" />}
              name="meta_title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="sites.meta_description" />}
              name="meta_description"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="sites.timezone" />}
              name="timezone"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="sites.currency" />}
              name="currency"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="sites.from_name" />}
              name="from_name"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="sites.from_email" />}
              name="from_email"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="sites.locale" />}
              name="locale"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="sites.hotline" />}
              name="hotline"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={false}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={true}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
