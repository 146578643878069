import api from "../utils/api";

class OrderApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get("/orders", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // create = (data) => {
  //   return new Promise((resolve, reject) => {
  //     return api
  //       .post("/orders", data)
  //       .then((res) => {
  //         resolve(res.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  // update = (id, data) => {
  //   return new Promise((resolve, reject) => {
  //     return api
  //       .put(`/orders/${id}`, data)
  //       .then((res) => {
  //         resolve(res.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  delete = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .delete(`/orders/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/orders/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const orderApi = new OrderApi();
export default orderApi;
