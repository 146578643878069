import api from "../utils/api";
class EzbinConfigsApi {

    create = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await api
                    .post("/ezbin-config", data);
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    };

    getConfig = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await api.get(`/ezbin-config/config`);
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    };

    update = (id, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await api.patch(`/ezbin-config/${id}`, data);
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    };
}

const ezbinConfigApi = new EzbinConfigsApi();
export default ezbinConfigApi;