import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import moment from "moment-timezone";
import notificationApi from "../../api/notification";

moment.tz.setDefault("US/Eastern")

const Notification = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reLoad, setReLoad] = useState(true)

    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            let result = await notificationApi.getAll()
            setItems(result.data)
            setLoading(false)
        }
        fetchData()
    }, [reLoad]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleOk = () => {
        Modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "Mark all the notifications as read? ",
            okText: "Yes",
            cancelText: "Cancel",
            onOk: () => markAllAsRead(),
        });
    };

    const onDelete = () => {
        Modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "Do you want to delete this item?",
            okText: "Yes",
            cancelText: "Cancel",
            onOk: () => DeleteNoti(),
        });
    }

    const onReload = () => setReLoad(!reLoad)

    const markAsRead = (record) => {
        notificationApi
            .updateMarkAsRead(record.id)
            .then((res) => {
                onReload()
            })
            .catch((error) => {
                console.error(error);
                Modal.error({
                    title: error.message,
                    okText: "Close",
                });
            });
    }

    const markAllAsRead = async () => {
        notificationApi
            .updateMarkAllAsRead()
            .then(() => {
                setSelectedRowKeys([])
                onReload()
            })
            .catch((error) => {
                console.error(error);
                Modal.error({
                    title: error.message,
                    okText: "Close",
                });
            });
    };

    const DeleteNoti = async () => {
        notificationApi
            .deleteNoti(selectedRowKeys)
            .then((res) => {
                onReload()
                setSelectedRowKeys([])
            })
            .catch((error) => {
                console.error(error);
                Modal.error({
                    title: error.message,
                    okText: "Close",
                });
            });
    };
    const columns = [
        {
            title: "Message",
            key: "message",

            dataIndex: "message",
            render: (text, record) => {
                return record.message;
            },
        },
        {
            title: "Time",
            key: "created_at",

            dataIndex: "created_at",
            render: (text, record) => {
                return moment(record.created_at).subtract(4, 'hours').format("MM-DD hh:mm a");
            },
        },
        {
            title: "",
            key: "featured",

            dataIndex: "featured",
            render: (text, record) => {
                return record.featured ? <CheckOutlined style={{ color: "#00b67a" }} /> : <Button onClick={() => markAsRead(record)} >{"Mark as read"}</Button>;
            },
        },
    ]

    return (
        <Card bordered={false} style={{ minHeight: "100%" }}>
            <Row justify="space-between" style={{ marginBottom: "10px" }} >
                <Col></Col>
                <Col>
                    <Button type="default" onClick={onReload} style={{ marginRight: "10px" }}>
                        {`Reload`}
                    </Button>
                    <Button type="default" disabled={!hasSelected} onClick={handleOk} style={{ marginRight: "10px" }} >
                        {`Mark all as read`}
                    </Button>
                    <Button type="default" disabled={!hasSelected} onClick={onDelete} >
                        {`Delete`}
                    </Button>

                </Col>
            </Row>

            <Table
                tableLayout="auto"
                columns={columns}
                dataSource={items}
                loading={loading}
                rowKey="id"
                rowSelection={rowSelection}
                size="small"
                scroll={{
                    x: "max-content",
                }}
                pagination={false}
            />
        </Card>

    )
}
export default Notification;