import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import {
  Button,
  Card,
  Table,
  Modal,
  Row,
  Col,
  Typography,
  DatePicker,
  Tag,
  Checkbox,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons"
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import moment from "moment-timezone";
import invoicesApi from "../../api/invoices";
import FilterBar from "../../components/FilterBar";
import driversApi from "../../api/drivers";
import InvoicesItem from "./InvoicesItem";
moment.tz.setDefault("US/Eastern");

Invoices.propTypes = {
  items: PropTypes.array,
};
Invoices.defaultProps = {
  items: [],
};
const { Text } = Typography;
function Invoices(props) {
  const isMount = useRef(false);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});
  const [itemModal, setItemModal] = useState(false);
  const [invoice, setInvoice] = useState();
  const [loadingPdfBtn, setLoadingPdfBtn] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "DESC",
  });

  const [drivers, setDrivers] = useState([]);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let drivers = await driversApi.getAll(filter);
        setDrivers(drivers.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await invoicesApi.getAll(filter);
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const intl = useIntl();

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "DESC";
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const openInvoicesPDF = (record) => {
    setLoadingPdfBtn(true)
    invoicesApi.getInvoicesPDF(record.id).then((res) => {
      setLoadingPdfBtn(false)
      Modal.info({
        title: (
          <div className="rv-tabs mb-3 contract-upload">
            <div style={{ marginBottom: "10px" }}>
              <a
                href={res.data}
                target="blank"
              >
                {`Click here to view PDF invoice`}
              </a>
            </div>
          </div>
        )
      })
    })
  }

  const changeConfirm = (record) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to change  confirmation status?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => actionConfirm(record.id, record.admin_confirm_status),
    })
  }

  const actionConfirm = (id, checked) => {
    invoicesApi.adminChangeConfirmStatus(id, { check: !checked })
      .then(() =>
        setReload(!reload)
      )
  }

  const changePaid = (record) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Do you want to change  payment status?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => actionChangePaid(record.id, record.admin_paid_status),
    })
  }

  const actionChangePaid = (id, checked) => {
    invoicesApi.adminChangePaidStatus(id, { check: !checked })
      .then(() =>
        setReload(!reload)
      )
  }

  const hasSelected = selectedRowKeys.length > 0;

  const onDelete = async (selectedRowKeys) => {
    await invoicesApi.delete(selectedRowKeys).catch((error) => {
      console.error(error);
      Modal.error({
        title: error.data.message,
        okText: intl.formatMessage({ id: "global.close" }),
      });
      setLoading(false);
    });
    setDeleting(true);
  };

  const openInvoicesItem = (record) => {
    setItemModal(true);
    setInvoice(record);
  };
  const closeInvoicesItem = () => {
    setItemModal(false);
  };

  const handleChangDate = (date, dateString) => {
    setFilter((f) => ({
      ...f,
      keyword: dateString,
    }));
  };

  const columns = [
    {
      title: <IntlMessages id="global.id" />,
      key: "id",
      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.invoice_no" />,
      key: "invoice_no",
      dataIndex: "invoice_no",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => openInvoicesItem(record)}
          >
            {record.invoice_no}
          </Button>
        );
      },
    },
    {
      title: "PDF",
      key: "invoice_no",
      dataIndex: "invoice_no",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            loading={loadingPdfBtn}
            onClick={() => openInvoicesPDF(record)}
          >
            {`View`}
          </Button>
        );
      },
    },
    {
      title: <IntlMessages id="global.from" />,
      key: "store",
      dataIndex: "store",
      render: (text, record) => record?.operator?.title
    },

    {
      title: <IntlMessages id="global.to" />,
      key: "invoice_no",
      dataIndex: "invoice_no",
      render: (text, record) => {
        return record?.operator?.belong_to === "ezbin" ? "Ezbin" : record?.store?.name
      },
    },

    {
      title: <IntlMessages id="global.job" />,
      key: "product_name",
      dataIndex: "product_name",
      render: (text, record) => {
        return (
          <>
            <p>{record.order.order_no}</p>
            <Text>
              {record.store && record.store.name
                ? record.store.name
                : null}
            </Text>
          </>
        );
      },
    },

    {
      title: <IntlMessages id="order.productName" />,
      key: "product_name",
      dataIndex: "product_name",
      render: (text, record) => {
        return record.product_name;
      },
    },

    {
      title: <IntlMessages id="global.total" />,
      key: "total",
      dataIndex: "total",
      render: (text, record) => {
        return `$${record.total}`;
      },
    },
    {
      title: "Confirmation",
      key: "confirm_status",
      dataIndex: "confirm_status",
      render: (text, record) => {
        return (
          <Checkbox checked={record.admin_confirm_status} onChange={() => changeConfirm(record)}>{`Confirmed`}</Checkbox>
        );
      },
    },
    {
      title: "Payment",
      key: "paid_status",
      dataIndex: "paid_status",
      render: (text, record) => {
        return (
          <Checkbox checked={record.admin_paid_status} onChange={() => changePaid(record)}>{`Confirmed`}</Checkbox>
        );
      },
    },
    // {
    //   title: <IntlMessages id="order.payStatus" />,
    //   key: "payStatus",

    //   dataIndex: "payStatus",
    //   render: (text, record) => {
    //     return record.pay_status === "pending" ||
    //       record.pay_status === "confirmed" ? (
    //       <Tag color="processing">{record.pay_status}</Tag>
    //     ) : record.pay_status === "success" ? (
    //       <Tag color="success">{record.pay_status}</Tag>
    //     ) : (
    //       <Tag color="error">{record.pay_status}</Tag>
    //     );
    //   },
    // },

    {
      title: <IntlMessages id="global.date" />,
      key: "date",
      dataIndex: "date",
      render: (text, record) => {
        return moment(record.date).format("MM-DD, hh:mm a");
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessages id="global.invoices" />} />
        </Col>

        <Col>
          <ActionBar
            onDelete={() => onDelete(selectedRowKeys)}
            isShowAddButton={false}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={true}
            isDisabled={!hasSelected}
          ></ActionBar>
        </Col>
      </Row>

      <FilterBar
        onFilter={onFilter}
        data={[
          {
            name: "driver",
            data: drivers,
            placeholder: <IntlMessages id="global.select_driver" />,
          },
        ]}
      >
        <Col>
          <DatePicker
            allowClear
            format={"YYYY-MM-DD"}
            onChange={handleChangDate}
          />
        </Col>
      </FilterBar>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />

      {itemModal ? (
        <InvoicesItem
          invoice={invoice}
          open={itemModal}
          onClose={closeInvoicesItem}
        ></InvoicesItem>
      ) : null}
    </Card>
  );
}

export default Invoices;
