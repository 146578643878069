import api from "../utils/api";

class UsersApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get("/users", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  create = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post("/users", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .put(`/users/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };
  delete = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .delete(`/users/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
const usersApi = new UsersApi;
export default usersApi;
