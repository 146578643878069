import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Switch, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import OperatorsForm from "./OperatorsForm";
import operatorsApi from "../../api/operators";
import moment from "moment-timezone";
import FilterBar from "../../components/FilterBar";
import UpdateAsignForm from "./UpdateAsignForm";
import storesApi from "../../api/stores";
import Orders from "./Orders";
import Invoices from "./Invoices";
import Truckers from "./Truckers";
import Trucks from "./Truck";

moment.tz.setDefault("US/Eastern");

Operators.propTypes = {
  items: PropTypes.array,
};
Operators.defaultProps = {
  items: [],
};

function Operators(props) {
  const isMount = useRef(false)
  const intl = useIntl();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [driverModal, setDriverModal] = useState(false);
  const [operatorId, setOperatorId] = useState();
  const [condition, setCondition] = useState({});
  const [store_ids, setStore_ids] = useState([])
  const [isOpenJob, setIsOpenJob] = useState(false)
  const [isInvoicesOpen, setIsInvoicesOpen] = useState(false)
  const [isOpenTruck, setIsOpenTruck] = useState(false)

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "DESC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (isMount) {
        let result = await storesApi.getStoreOwnFleet()
        setStore_ids(result.data);
      }
    }
    fetchData()
    return () => {
      isMount.current = false;
    };
  }, []);

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await operatorsApi.getAllFleet(filter);
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const [modalAsign, setModalAsign] = useState(false)
  const [idOperator, setIdOperator] = useState(null)

  const onOpenModalAsign = (record = null) => {
    setModalAsign(true);
    setIdOperator(record.id);
  };

  const onCloseModalAsign = () => {
    setModalAsign(false);
  };

  const onOpenDriver = (id) => {
    setDriverModal(true);
    setOperatorId(id);
  };

  const closeDriver = () => {
    setDriverModal(false);
  };

  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await operatorsApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() { },
    });
  };

  const onChangeStatus = async (ischecked, record) => {
    Modal.confirm({
      title: ischecked ? intl.formatMessage({ id: "global.activate_confirm" }) : intl.formatMessage({ id: "global.deactivate_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await operatorsApi.updateStatus(record.id, { status: ischecked }).then((res) =>
          setItems((items) =>
            items.map((data) => {
              if (data.id === record.id)
                return {
                  ...data,
                  status: ischecked,
                };
              return data;
            })
          )).catch((err) => {
            Modal.error({
              title: err.data.message,
              okText: intl.formatMessage({ id: "global.close" }),
            });
          })
      },
      onCancel() { },
    })

  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "DESC";
  };

  const handleOpenJobModal = (id) => {
    setIsOpenJob(true)
    setOperatorId(id)
  }

  const handleOpenInvoices = (id) => {
    setIsInvoicesOpen(true)
    setOperatorId(id)
  }

  const handleOpenTruck = (id) => {
    setIsOpenTruck(true)
    setOperatorId(id)

  }

  const columns = [
    {
      title: <IntlMessages id="global.id" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.title" />,
      key: "title",
      dataIndex: "title",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
          >
            {record.title}
          </Button>
        );
      },
    },
    {
      title: <IntlMessages id="global.fullname" />,
      key: "firstname",
      dataIndex: "firstname",
      render: (text, record) => {
        return `${record.firstname} ${record.lastname}`;
      },
    },
    {
      title: <IntlMessages id="global.email" />,
      key: "email",
      dataIndex: "email",
      render: (text, record) => {
        return record.email ? record.email : null;
      },
    },
    {
      title: <IntlMessages id="global.phone" />,
      key: "mobile",
      dataIndex: "mobile",
      render: (text, record) => {
        return record.phone_code
          ? `(${record.phone_code}) ${record.mobile}` : record.mobile
      },
    },

    {
      title: <IntlMessages id="global.belongto" />,
      dataIndex: "belong_to",
      width: 100,
      key: "belong_to",
      render: (e, record) =>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
          <span>{record?.belong_to === "ezbin" ? <Tag color="green">{`Ezbin`}</Tag> : record?.store?.name}</span>
          <Button
            type="primary"
            size="small"
            style={{ marginTop: "2px" }}
            onClick={() => onOpenModalAsign(record)}
            shape="round"
          >
            <IntlMessages id="global.update" />
          </Button>
        </div>


    },

    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      key: "status",
      render: (e, record) =>
        record ? (
          <Switch
            checkedChildren=""
            unCheckedChildren=""
            checked={record.status}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        ) : null,
    },
    {
      title: "",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenJobModal(record.id)}
          >
            {`Jobs`}
          </Button>
        );
      },
    },

    {
      title: "",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenInvoices(record.id)}
          >
            {`Invoices`}
          </Button>
        );
      },
    },

    {
      title: "",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenDriver(record.id)}
          >
            {<IntlMessages id="global.drivers" />}
          </Button>
        );
      },
    },

    {
      title: "",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenTruck(record.id)}
          >
            {<IntlMessages id="global.cars" />}
          </Button>
        );
      },
    },

    {
      title: "Delete",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          <PageTitle title="Fleet Owner" />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      <FilterBar
        onFilter={onFilter}
        data={[
          {
            name: "store",
            data: store_ids,
            placeholder: <IntlMessages id="global.select_contractor" />,
          }
        ]}

      >
      </FilterBar>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <OperatorsForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
      {modalAsign ?
        <UpdateAsignForm
          open={modalAsign}
          onClose={onCloseModalAsign}
          idOperator={idOperator}
          onReload={onReload}
        /> : null
      }
      {driverModal ? (
        <Truckers
          open={driverModal}
          onClose={closeDriver}
          operatorId={operatorId}
        />
      ) : null}
      {isOpenJob ?
        <Orders
          open={isOpenJob}
          onClose={() => setIsOpenJob(false)}
          operatorId={operatorId}
        />
        : null
      }
      {isInvoicesOpen ?
        <Invoices
          open={isInvoicesOpen}
          onClose={() => setIsInvoicesOpen(false)}
          operatorId={operatorId}
        />
        : null
      }
      {isOpenTruck ?
        <Trucks
          open={isOpenTruck}
          onClose={() => setIsOpenTruck(false)}
          operatorId={operatorId}
        />
        : null
      }
    </Card>
  );
}

export default Operators;
