import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AppLayout from '../layouts';
import { routes } from '../routes';
import NotFoundPage from '../routes/error/NotFoundPage';

class AppRoutes extends React.Component {
    componentDidMount() {

    }
    componentWillUnmount() {
    }
    render() {
        const { match, authUser } = this.props;
        if (!authUser) {
            return <Redirect to='/login' />;
        }




        return (
            <React.Fragment>
                <Helmet>
                    {/* <title>{title}</title> */}
                </Helmet>
                <AppLayout>
                    <Switch>
                        {
                            routes.map((route, index) => {
                                return (
                                    <ProtectedRoute exact authUser={authUser} permission={route.permission} path={`${match.url}/${route.path}`} component={route.component} key={index} />
                                )
                            })
                        }
                        <Route component={NotFoundPage} />
                    </Switch>
                </AppLayout>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

export default withRouter(connect(mapStateToProps)(AppRoutes));


const ProtectedRoute = ({ authUser, permission, component: Component, ...rest }) => (
    <Route
        {...rest}
        exact
        render={(props) => {
            if (authUser) {

                return <Component {...props} />;
            } else {
                return <Redirect to='/login' />;
            }
        }}
    />
)



