export default {
  // phan quyen
  "admin.customer.list": "Xem danh sách khách hàng",
  "admin.customer.create": "Thêm mới khách hàng",
  "admin.customer.detail": "Xem chi tiết khách hàng",
  "admin.customer.update": "Cập nhật thông tin khách hàng",
  "admin.customer.remove": "Xoá khách hàng",
  "admin.customer.export": "Download thông tin khách hàng",

  "admin.potential_customer.list": "Xem danh sách khách hàng tiềm năng",
  "admin.potential_customer.create": "Thêm mới khách hàng tiềm năng",
  "admin.potential_customer.detail": "Xem chi tiết khách hàng tiềm năng",
  "admin.potential_customer.update": "Cập nhật thông tin khách hàng tiềm năng",
  "admin.potential_customer.remove": "Xoá khách hàng tiềm năng",

  "admin.customer_history.list": "Xem lịch sử làm việc khách hàng",
  "admin.customer_history.create": "Thêm mới lịch sử làm việc khách hàng",
  "admin.customer_history.remove": "Xoá lịch sử làm việc khách hàng",

  "admin.datacenter_request.list": "Xem danh sách yêu cầu Datacenter",
  "admin.datacenter_request.create": "Thêm mới yêu cầu Datacenter",
  "admin.datacenter_request.detail": "Xem chi tiết yêu cầu Datacenter",
  "admin.datacenter_request.remove": "Xoá yêu cầu Datacenter",
  "admin.datacenter_request.export": "Download danh sách yêu cầu Datacenter",

  "admin.documentation.list": "Xem danh sách tài liệu",
  "admin.documentation.create": "Thêm mới tài liệu",
  "admin.documentation.detail": "Xem chi tiết tài liệu",
  "admin.documentation.update": "Cập nhật thông tin tài liệu",
  "admin.documentation.remove": "Xoá tài liệu",

  "admin.notification.list": "Xem danh sách thông báo",
  "admin.notification.create": "Thêm mới thông báo",
  "admin.notification.detail": "Xem chi tiết thông báo",
  "admin.notification.update": "Cập nhật thông báo",
  "admin.notification.remove": "Xoá thông báo",

  "admin.partner.list": "Xem danh sách đối tác",
  "admin.partner.create": "Thêm mới đối tác",
  "admin.partner.detail": "Xem chi tiết đối tác",
  "admin.partner.update": "Cập nhật thông tin đối tác",
  "admin.partner.remove": "Xoá đối tác",

  "admin.product.list": "Xem danh sách dịch vụ",
  "admin.product.create": "Thêm mới dịch vụ",
  "admin.product.detail": "Xem chi tiết dịch vụ",
  "admin.product.update": "Cập nhật thông tin dịch vụ",
  "admin.product.remove": "Xoá dịch vụ",
  "admin.product.send_postage": "Gửi thông báo cước dịch vụ",
  "admin.product.send_overload_alert": "Gửi thông báo quá tải",
  "admin.product.export": "Download danh sách dịch vụ",

  "admin.product_category.list": "Xem danh sách danh mục dịch vụ",
  "admin.product_category.create": "Thêm mới danh mục dịch vụ",
  "admin.product_category.detail": "Xem chi tiết danh mục dịch vụ",
  "admin.product_category.update": "Cập nhật thông tin danh mục dịch vụ",
  "admin.product_category.remove": "Xoá danh mục dịch vụ",

  "admin.service.list": "Xem danh sách sản phẩm",
  "admin.service.create": "Thêm mới sản phẩm",
  "admin.service.detail": "Xem chi tiết sản phẩm",
  "admin.service.update": "Cập nhật thông tin sản phẩm",
  "admin.service.remove": "Xoá sản phẩm",

  "admin.support_request.list": "Xem danh sách yêu cầu hỗ trợ",
  "admin.support_request.create": "Tạo yêu cầu hỗ trợ",
  "admin.support_request.detail": "Xem chi tiết yêu cầu hỗ trợ",
  "admin.support_request.update": "Cập nhật yêu cầu hỗ trợ",
  "admin.support_request.response": "Phản hồi hỗ trợ",

  "admin.user.list": "Xem danh sách user",
  "admin.user.create": "Thêm mới user",
  "admin.user.detail": "Xem chi tiết user",
  "admin.user.update": "Cập nhật thông tin user",
  "admin.user.remove": "Xoá user",

  "admin.permissions.list": "Xem danh sách quyền",
  "admin.permissions.update": "Phân quyền",
  "admin.permissions.get_by_role": "Lấy danh sách quyền theo vai trò",

  "admin.task.list": "Xem danh sách công việc hằng ngày",
  "admin.task.create": "Thêm mới công việc hằng ngày",
  "admin.task.detail": "Xem chi tiết công việc hằng ngày",
  "admin.task.update": "Cập nhật thông tin công việc hằng ngày",
  "admin.task.remove": "Xoá công việc hằng ngày",

  "admin.role.list": "Xem danh sách vai trò",
  "admin.role.create": "Thêm mới vai trò",
  "admin.role.detail": "Xem chi tiết vai trò",
  "admin.role.update": "Cập nhật thông tin vai trò",
  "admin.role.remove": "Xoá vai trò",

  "admin.business.get_revenue_in_month": "Xem báo cáo doanh thu trong tháng",
  "admin.business.get_report": "Xem báo cáo của nhân viên",
  "admin.business.list_transaction": "Xem lịch sử giao dịch",
  "admin.business.create_transaction": "Tạo mới giao dịch",
  "admin.business.detail_transaction": "Xem chi tiết giao dịch",
  "admin.business.update_transaction": "Cập nhật giao dịch",
  "admin.business.remove_transaction": "Xoá giao dịch",
  "admin.potential_customer.import": "Import từ Excel",

  "admin.warehouse.list": "Xem danh sách thiết bị trong kho",
  "admin.warehouse.create": "Thêm mới thiết bị vào kho",
  "admin.warehouse.detail": "Xem chi tiết thiết bị",
  "admin.warehouse.update": "Cập nhật thông tin thiết bị",
  "admin.warehouse.remove": "Xoá thiết bị",

  "admin.order.list": "Xem danh sách yêu cầu mua hàng",
  "admin.order.detail": "Xem chi tiết yêu cầu mua hàng",
  "admin.order.update": "Cập nhật yêu cầu mua hàng",

  "admin.shop.list": "Xem danh sách gian hàng đối tác",
  "admin.shop.create": "Thêm mới gian hàng đối tác",
  "admin.shop.detail": "Xem chi tiết gian hàng đối tác",
  "admin.shop.update": "Cập nhật thông tin gian hàng đối tác",
  "admin.shop.remove": "Xoá gian hàng đối tác",

  "admin.service_category.list": "Xem danh sách danh mục sản phẩm",
  "admin.service_category.create": "Thêm mới danh mục sản phẩm",
  "admin.service_category.detail": "Xem chi tiết danh mục sản phẩm",
  "admin.service_category.update": "Cập nhật thông tin danh mục sản phẩm",
  "admin.service_category.remove": "Xoá danh mục sản phẩm",

  "admin.mail.send": "Gửi mail tới khách hàng",
  "admin.mail.send_many": "Gửi thông báo chăm sóc (tới nhiều khách hàng)",
  "admin.mail.get_template": "Xem mẫu email",
  "admin.mail.set_template": "Cập nhật mẫu email",

  "admin.web_customer.list": "Xem danh sách khách hàng từ web",
  "admin.web_customer.detail": "Xem chi tiết khách hàng từ web",
  "admin.web_customer.update": "Cập nhật khách hàng từ web",
  "admin.web_customer.remove": "Xoá khách hàng từ web",

  "admin.export.customer": "Download dữ liệu khách hàng",
  "admin.export.product":
    "Download dữ liệu dịch vụ (bao gồm đang chạy, cắt huỷ, tạm dừng)",
  "admin.export.datacenter_request": "Download yêu cầu datacenter",
  "admin.export.support_request": "Download yêu cầu hỗ trợ từ khách hàng",
  "admin.export.customer_history": "Download lịch sử làm việc với khách hàng",
  "admin.export.product_history": "Download lịch sử thay đổi dịch vụ",
  "admin.export.product_overload": "Download thông báo dịch vụ quá tải",
  "admin.product_overload.list": "Xem danh sách thông báo quá tải",

  "admin.customer_support_calls.list": "Xem danh sách khách hàng cần chăm sóc",
  "admin.customer_support_calls.create": "Tạo ghi chú sau khi chăm sóc",

  // card title
  "admin.customer": "Khách hàng",
  "admin.customer_history": "Lịch sử làm việc khách hàng",
  "admin.datacenter_request": "Yêu cầu datacenter",
  "admin.documentation": "Tài liệu",
  "admin.notification": "Thông báo khuyến mãi",
  "admin.partner": "Đối tác datacenter",
  "admin.permissions": "Phân quyền",
  "admin.potential_customer": "Khách hàng tiềm năng",
  "admin.product": "Dịch vụ",
  "admin.product_category": "Danh mục dịch vụ",
  "admin.role": "Quản lý vai trò",
  "admin.service": "Sản phẩm đối tác",
  "admin.support_request": "Yêu cầu hỗ trợ",
  "admin.task": "Công việc hằng ngày",
  "admin.user": "User",
  "admin.warehouse": "Kho thiết bị",
  "admin.business": "Báo cáo kinh doanh",
  "admin.shop": "Gian hàng đối tác",
  "admin.order": "Yêu cầu mua hàng",
  "admin.service_category": "Danh mục sản phẩm đối tác",
  "admin.mail": "Mail",
  "admin.web_customer": "Khách hàng từ web",
  "admin.export": "Download dữ liệu",
  "admin.product_overload": "Thông báo quá tải",
  "admin.customer_support_calls": "Chăm sóc khách hàng",
  "global.avatar": "Ảnh",
  "global.full_name": "Họ tên",
  "global.created": "Created At",
  "global.role": "Role",
  "global.add": "Add New",
  "global.password": "Password",
  "global.resetpassword": "Reset password",
  "global.input_password": "Input password",
  "global.is_verified_email": "Verify email",
  "global.is_mobile_verified": "Verify phone",
  "global.unverified": "Unverified",
  "global.verified": "Verified",
  "global.admin": "Quản trị viên",
  "global.staff": "Nhân viên",
  "global.gender": "Giới tính",
  "global.date_of_bird": "Date of birth",
  "global.identity": "CMT/CCCD",
  "global.bhyt": "BHYT",
  "global.nam": "Nam",
  "global.nu": "Nữ",
  "global.ngay_cap": "Ngày cấp",
  "global.noi_cap": "Nơi cấp",
  "global.so_bhyt": "Số BHYT",
  "widgets.recentNotifications": "Thông báo gần đây",
  "button.viewAll": "Xem tất cả",
  "widgets.recentMessage": "Tin nhắn gần đây",
  "global.user": "Người dùng",
  "global.status": "Status",
  "global.add_new": "Add New",
  "global.published": "Published",
  "global.unpublished": "Unpublished",
  "global.delete": "Delete",
  "global.create": "Create",
  "global.update": "Update",
  "global.image": "Image",
  "global.title": "Title",
  "global.company": "Company",
  "global.id": "ID",
  "global.tabbasic": "Basic",
  "global.alias": "Alias",
  "global.description": "Description",
  "global.cancel": "Cancel",
  "global.active": "Active",
  "global.deactivate": "Deactivate",
  "global.submit": "Submit",
  "global.edit": "Edit",
  "global.trashed": "Trashed",
  "global.approved": "Approved",
  "global.from": "From",
  "global.to": "To",
  "global.home": "Home",
  "global.price_discount": "Price Discount",
  "global.price_roundtrip": "Price Roundtrip",
  "global.price": "Price ",
  "global.car": "Car",
  "global.type": "Type",
  "global.room_rate": "Room rate",
  "global.cancel_policy": "Cancel policy",
  "global.instant_bookable": "Instant Bookable",
  "global.yes": "Yes",
  "global.no": "No",
  "global.city": "City",
  "global.street": "Street",
  "global.neighbourhood_cleansed": " Cleansed",
  "global.neighbourhood_group_cleansed": " Group Cleansed",
  "global.location": "Location",
  "global.choose-location": "Choose location",
  "global.square_feet": "Square Feet",
  "global.bedrooms": "Bedrooms",
  "global.bathrooms": "Bathrooms",
  "global.beds": "Beds",
  "global.bed_type": "Bed_type",
  "global.cover_img": "Cover image",
  "global.gallery": "Gallery",
  "global.gallerySetting": "Gallery Setting",
  "global.security_deposit": "Security deposit ",
  "global.guests_included": "Guests included",
  "global.minimum_nights": "Minimum nights",
  "global.cleaning_fee": "Cleaning fee",
  "global.extra_people": "Extra people",
  "global.maximum_nights": "Maximum nights",
  "global.months": "Months",
  "global.calendar": "Calendar",
  "global.province": "Province",
  "global.district": "District",
  "global.ward": "Ward",
  "global.no_house": "House number",
  "global.nearbyUtils": "Near by Utils",
  "global.extra_price": "Extra price",
  "global.patients": "Patients",
  "global.setting": "Setting",
  "global.appointments": "Appointments",
  "global.suppliers": "Suppliers",
  "global.id_number": "ID",
  "global.code": "Code",
  "global.drivers_location": "Trucker Location",
  "global.name": "Name",
  "global.services": "Services",
  "global.clinic": "Clinic",
  "global.website": "Website",
  "global.contact_name": "Contact",
  "global.refresh": "Refresh",
  "global.Store": "Store",
  "global.address": "Address",
  "global.Rate": "Rate",
  "global.invite": "Invite",
  "global.date": "Date",
  "invite.invite_status": "Invite Status",
  "invite.qty": "qty",
  "invite.trucks": "Trucks",
  "global.select_driver": "Select Driver",
  "global.drivers": "Trucker",
  "global.weight": "Weight",
  "global.dinvites": "Dinvites",
  "global.job": "Job",
  "global.work_start": "Works start at",
  "global.belongto": "Belong To",
  "global.select_contractor": "Seclect Contractor",
  "global.fleet": "Fleet Owner",
  "global.select_fleet": "Select Fleet's title",


  "car.driver": "Driver",
  "car.seat": "Seat",
  "car.luggage": "Luggage",
  "car.ordering": "Ordering",
  "car.facility": "Facility",
  "car.air_machine": "Air machine",
  "car.energy_saving": "Energy saving",
  "car.stop_point": "Stop point",
  "property.start_date": "Start date ",
  "property.end_date": "End date",
  "property.price_week": "Price week",
  "property.weekend_price": "Price weekend",
  "property.extra_price": "Extra price",
  "property.host_languages": " Host Languages",
  "property.week_term_promo": "Week term promo",
  "property.month_term_promo": "Month term promo",
  "property.price_weekend": "Price weekend",
  "global.guests_standard": "Guests standard",
  "global.guests_max": "Guests max",
  "sidebar.transport": "Transport",
  "sidebar.amenity": "Amenity",
  "sidebar.restaurant": "Restaurant",
  "sidebar.property": "Property",
  "sidebar.property_type": "Property Type",
  "sidebar.room": "Room",
  "sidebar.cancel_policy": "Cancel Policy",
  "sidebar.vehicle": "Vehicle",
  "sidebar.car": "Car",
  "sidebar.route": "Route",
  "sidebar.inbox": "Inbox",
  "sidebar.conversation": "Conversation",
  "sidebar.reservations": "Reservations",
  "sidebar.earning": "Earning",
  "sidebar.calendar": "Calendar",
  "global.requiredfield": "Requiredfield",
  "global.assiged_trucking_company": "Personal Fleet",

  "property.room_type": "Room type",
  "global.propertytypes": "Property Type",
  "global.save": "Save",
  "global.signin": "Signin",
  "global.signup": "Signup",
  "global.search_placeholder": "Search",

  //Tour
  "global.duration": "Duration",
  "global.category": "Category",
  "global.cover_image": "Cover",
  "global.operator": "Operator",
  "global.from_price": "Prices",
  "global.itineraries": "Itinerary",
  "global.updated_at": "Updated at",
  "global.from_priceMGMT": "Prices",
  "global.from_price_setting": " Prices Setting",
  "global.ItineraryMGMT": " Itineraries",
  "global.tour": "Tour",

  "global.specific_partner": "Assigned Trucking Company",

  "global.PriceMGMT": "Price Management",
  "global.itinerariesMGMT": "Itienary Management",
  "tour.title": "Title",
  "global.select_category": "Select Category",
  "global.select_operator": "Select Operator",
  "tour.description": "Tour Description",
  "tour.inclusion": "Inclusion",
  "tour.exclusion": "Exclusion",
  "global.inactive": "Inactive",
  "global.meal": "Meals",
  "global.category_operator": "Category and Operator",
  "tour.pricing": "Pricing model",
  "tour.special": "Special",
  "tour.normal": "Normal",
  "tour.featured": "Featured",
  "tour.time_mgmt": "Time management",
  "tour.duration_unit": "Duration Unit",
  "tour.duration": "Duration",
  "tour.start_time": "Start Time",
  "global.delete_confirm": "Are you sure want to delete this item?",
  "tour.gallery": "Gallery",
  "global.ticket": "Ticket",
  "global.min_person": "Min Person",
  "global.freeCancelHours": "Free Cancel Hours",
  "global.freeCancel": "Free cancel",
  "tour.freeCancel": "Free Cancel",
  "global.cancelPolicy": "Cancel Policy",
  "global.meeting": "Meeting point",
  "global.meetingPoint": "Meeting point",
  "global.meetingPointNotes": "Meeting Point Notes",
  "tour.cutOffTime": "Cut Off Time(hours)",
  "tooltip.cutOffTime": "e.g: 2 days before departure date is 48 hours",
  "global.perPerson": "Per Person",
  "global.perGroup": "Per Group",
  "global.optionTitle": "Option Title",
  "global.checkall": "Check All",
  "global.truck": "Truck",
  "global.driver": "Driver",
  "global.loads": "Loads",
  "global.order_no": "Order No.",
  "global.project_no": "Project ID",
  "global.activate_confirm": "Do you want to activate this account?",
  "global.deactivate_confirm": "Do you want to deactivate this account?",


  //Option
  "global.max_person": "Max Person",
  "global.adult_price": "Adult Price",
  "global.child_price": "Child Price",
  "global.select_tour": "Select Tour",
  "global.option": "Option",
  "option.group": "Group",
  "global.Vehicles": "Vehicles",
  "global.timeStart": "Time Start",

  //Destination
  "global.destination": "Destination",
  "global.select_destination": "Select Destination",
  "global.countries": "Country",
  "global.select_country": "Select Country",
  "destination.longitude": "Longitude",
  "destination.latitude": "Latitude",
  "global.destination_parent": "Destination Parent",

  //Categories
  "global.categories": "Category",

  //Operators
  "global.operators": "Operator",
  "global.firstname": "First Name",
  "global.lastname": "Last Name",
  "global.email": "Email",
  "global.phone": "Phone",
  "operator.fleet": "FLEET",
  "operator.single": "SINGLE",

  //Cars
  "global.cars": "Trucks",
  "global.serial": "Serial",
  "global.truckSize": "Truck Size",

  //Currencies
  "global.currencies": "Currency",
  "currency.name": "Name",
  "currency.display": "Display",
  "currency.exchange_rate": "Exchange rate",
  "currency.thousand": "Format",
  "currency.image": "Icon",
  "currency.symbol": "Symbol",
  //post
  "global.posts": "Post",
  "post.fulltext": "Full Text",
  "post.short": "Short",
  "global.featured": "Featured",
  "post.hit": "Hit",
  "post.metadesc": "Metadesc",
  "post.metakey": "Metakey",
  "post.image": "Image",

  //Log-in Log-out
  "global.account_information": "Account Information",
  "global.signout": "Signout",
  "global.email_required": "Email Required",
  "global.password_required": "Password Required",
  "global.email_not_valid": "Email not valid",

  //Site:
  "global.site": "Site",
  "sites.hotline": "Hotline",
  "sites.locale": "Locale",
  "sites.from_email": "From Email",
  "sites.from_name": "FRom Name",
  "sites.currency": "Currency",
  "sites.timezone": "Time Zone",
  "sites.meta_description": "Meta Description",
  "sites.meta_title": "Meta Title",
  "sites.email": "Email",
  "global.sites": "Sites",
  //Orders:
  "global.order": "Orders",
  "global.orderId": "Code",
  "order.tour_title": "Tour Title",
  "order.payStatus": "Pay Status",
  "order.orderStatus": "Order Status",
  "order.departDate": "Depart Date",
  "order.customer": "Customer",
  "order.totalTravelers": "Total Traveler",
  "order.totalPrice": "Total Price",
  "order.created": "Booked At",
  "order.productName": "Product Name",
  "order.qty": "qty",
  "order.location": "Location",
  "aggregate": "Aggregate",
  "dirt": "Dirt",
  "rough": "Rough",
  "job_detail": "Job Detail",
  "global.accepted_time": "Accepted Time",
  "global.jobs": "Jobs",
  "global.trucks": "Trucks",
  "global.expired": "Expired Time",
  "global.start_at": "Start At",
  "global.end_at": "End At",
  "global.invoice_details": "Invoices Details",
  "global.driver_status": "Driver Status",
  "global.work_on": "Work on",
  "global.order_end_date": "Job End Date",


  //Customers:
  "global.customers": "Customer",
  "global.fullname": "Full name",
  "global.userId": "User ID",
  //Users:
  "global.roles": "Role",
  "global.users": "Users",

  //Schedule
  "global.schedule_setting": "Schedule Setting",
  "global.schedule": "Schedule",
  "global.start_date": "Start date",
  "global.end_date": "End date",
  "global.weekdays": "Week days",



  //wishlist
  "global.wishlist": "Wishlist",
  "wishlist.tour_title": "Tour Title",
  "wishlist.customer": "Customer",
  "global.basic": "Basic",
  "global.item": "Item",
  "global.items": "Items",
  "global.sku": "SKU",
  "global.widgets": "Widgets",
  "global.widget": "Widget",


  //Register
  "global.registration_header": "Register Form",
  "global.country": "Country",
  "global.mobile": "Mobile",
  "signup.back_to_login": "Back to login",
  "global.continue": "Continue",
  "global.close": "Close",


  "global.language": "Language",
  "global.currency": "Currency",
  "global.your_plan": "Plan",
  "global.trial_end": "Expired date",
  "global.timezone": "Timezone",
  "global.forgot_password": "Forgot password",
  "global.emai_required": "Yêu cầu điền email",
  "global.emai_not_valid": "Email không đúng định dạng",
  "global.confirm_password": "Confirm password",
  "global.changepassword": "Change password",
  "global.confirm_new_password": "Confirm new password",
  "global.new_password": "New password",
  "global.current_password": "Current password",
  "global.password_not_match": "Password not match",
  "global.password_min_charactor": "6 characters min",
  "global.recover_password": "Recover password",
  "global.email_reset_password": "Fill email to recover password",

  // subcribers
  "global.user_id": "User ID",
  "global.device_id": "Device ID",
  "global.os": "OS",
  "global.subcribers": "Subcribers",

  //invoices
  "global.invoices": "Invoices",
  "global.view": "View",
  "global.invoice_no": "Invoice No",
  "global.total": "Total",
  //menu
  "global.contractor": "Contractor",
  "global.fleet_owner": "Fleet owner",
  "global.owner_operator": "Owner Operator",
  "global.notification": "Notification",
  "global.contract": "Contract",
  "global.tax_id_no": "Tax ID Number",
  "global.motor_carrier_no": "Motor Carrier Number",
  "global.us_dot_no": "US DOT Number",
  "global.insurance_card": "Insurance Card",
  "global.truck_picture": "Truck's photo"
};
