import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Radio,
  InputNumber,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import storesApi from "../../api/stores";
import ratesApi from "../../api/rates";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function RateForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  //console.log("?",loading)
  const intl = useIntl();
  const rolesTypes = {
    Ton: "ton",
    Hour: "hour",
    Fuel_Surcharge_Ton: "fuel_surcharge_ton",
    Fuel_Surcharge_Tour: "fuel_surcharge_hour"
  };
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let stores = await storesApi.getAll(filter);
        setStores(stores.data);

      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        //data.propertytypes = JSON.stringify(data.propertytypes);
        console.log("values", data);

        if (currentData) {
          ratesApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          ratesApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",

    type: currentData && currentData.type ? currentData.type : null,
    price: currentData && currentData.price ? currentData.price : "",
    store_id: currentData && currentData.store && currentData.store.id ? currentData.store.id : null,
    status: currentData && currentData.status ? 1 : 0,
  };

  //console.log("initialValue", initialValue);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.Rate" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.Store" />}
              name="store_id"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {stores.map((user, index) => (
                  <Option key={user.id} value={user.id}>
                    {user.email}
                  </Option>
                ))}
                ;
              </Select>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.type" />}
              name="type"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {Object.entries(rolesTypes).map(([key, value]) => (
                  <Option key={value}>{key}</Option>
                ))}
                ;
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.price" />}
              name="price"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
