import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Modal, Button, Avatar, Row, Col, Popover, Divider, Space, Typography } from 'antd';
import {
    MenuFoldOutlined,
    ExclamationCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
// actions
import { logout } from '../redux/actions/AuthActions';
import AppDrawer from './AppDrawer';
import IntlMessages from "../components/IntlMessage"
import Search from 'antd/lib/input/Search';
import { injectIntl } from 'react-intl'
import DrawerPatient from '../components/Drawer';
const { Text } = Typography;
const { Header } = Layout;
const { confirm } = Modal;
class AppHeader extends Component {

    state = {
        visible: false,
        visibleDrawer: false,
        namePatien: '',
    }


    toggleDrawer(visible) {
        this.setState({ visible })
    }
    closeDrawer(visibleDrawer) {
        this.setState({ visibleDrawer })
    }


    onLogout() {
        confirm({
            title: 'Are you sure to log out?',
            icon: <ExclamationCircleOutlined />,

            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => {
                this.props.logout().then(() => {
                    window.location.href = '/login'
                })
            }
        })
    }
    setName(account) {
        if (account) {
            var name = "";
            if (account.firstname) name = name + " " + account.firstname;
            if (account.lastname) name = name + " " + account.lastname;
            if (name) return name;
            return "Guest User";
        }
    }
    render() {
        var { visible } = this.state;
        var { visibleDrawer } = this.state;
        var { namePatient } = this.state;
        var { authUser } = this.props;
        const prefix = (
            <SearchOutlined
                style={{
                    fontSize: 16,
                    color: '#1890ff',
                }}
            />
        );

        const { formatMessage } = this.props.intl;
        const onSearch = (value) => {
            this.setState({ namePatient: value })
            this.setState({ visibleDrawer: true })

        }
        return (
            <Header className="site-header-background">
                <Row justify="space-between">
                    <Col >
                    </Col>
                    <Col>

                        <Row align="middle" justify="space-around">

                            <Col>
                                <Row align="middle">
                                    <Search
                                        placeholder={formatMessage({ id: 'global.search_placeholder' })}
                                        onSearch={onSearch}
                                        size="large"
                                        prefix={prefix}

                                    />
                                </Row>

                            </Col>

                            <Col>
                                <Divider type="vertical" style={{ "height": "1.5em" }}></Divider>
                                {
                                    isMobile ? (
                                        <Button
                                            type="link"
                                            icon={<MenuFoldOutlined className="header-icons" />}
                                            onClick={() => this.toggleDrawer(true)}
                                        />
                                    ) : (
                                        <React.Fragment>

                                            <Popover
                                                trigger="click"
                                                className="header-actions"
                                                placement="bottomRight"
                                                content={
                                                    <>
                                                        <Row gutter={[20, 20]}>
                                                            <Col>
                                                                <Row align="middle" justify='space-around'>
                                                                    <Avatar style={{ backgroundColor: '#f56a00' }} >{authUser.lastname.charAt(0)}
                                                                    </Avatar>
                                                                </Row>
                                                            </Col>
                                                            <Col>

                                                                <Text strong>{authUser.lastname + ' ' + authUser.firstname}</Text>
                                                                <br></br>
                                                                <Text style={{ fontSize: "12px" }} type="secondary" >{authUser.email}</Text>

                                                            </Col>
                                                        </Row>
                                                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                                        <Link to='/app/profile'>

                                                            <Button type="link">
                                                                <IntlMessages id="global.account_information" />
                                                            </Button>
                                                        </Link>



                                                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                        <Button type="link" onClick={() => this.onLogout()}>
                                                            <Space><IntlMessages id="global.signout" /></Space>
                                                        </Button>
                                                    </>
                                                }
                                                title={null}
                                            >
                                                {authUser.image ? <Avatar src={this.props.config.url_asset_root + authUser.image} /> :
                                                    <Avatar style={{ backgroundColor: '#f56a00' }} >{authUser.lastname.charAt(0)}
                                                    </Avatar>}


                                            </Popover>
                                        </React.Fragment>
                                    )
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row >
                <AppDrawer visible={visible} onClose={() => this.toggleDrawer(false)} />
                <DrawerPatient item={namePatient} visible={visibleDrawer} onClose={() => this.closeDrawer(false)} />
            </Header >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    }
}


export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader)));