
import api from '../utils/api';

class InvoicesApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("invoices/invoiceoperator", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getByOrderId = (OrderId, DriverId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/invoices/order/${OrderId}/driver/${DriverId}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOperatorInvoiceItem = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/invoice-items/operator/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  getCustomerInvoiceItem = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/invoice-items/customer/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post("/invoices", data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .patch(`/invoices/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .delete(`/invoices/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/invoices/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getInvoicesPDF = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/invoices/pdfoperator/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  changeConfirmStatus = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(`/front/customer/confirm/invoice/${id}`);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  changePaidStatus = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(`/front/customer/paid/invoice/${id}`);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  adminChangeConfirmStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(`/front/invoices/adminconfirm/${id}`, data);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  adminChangePaidStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(`/front/invoices/adminpaid/${id}`, data);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

}
const invoicesApi = new InvoicesApi();
export default invoicesApi;