import { Modal, Table } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import IntlMessages from "../../components/IntlMessage";
moment.tz.setDefault("US/Eastern");

const Loads = (props) => {
  const { data, open, onClose } = props;

  const handleCancel = () => {
    onClose();
  };

  const [openImg, setOpenImg] = useState(false)
  const [img, setImg] = useState("")

  const showImg = (record) => {
    setOpenImg(true)
    setImg(record.image)
  }

  const closeImg = () => {
    setOpenImg(false)
  }
  const columns = [
    {
      title: <IntlMessages id="global.ticket" />,
      key: "image",
      dataIndex: "image",
      render: (text, record) => {
        return record.image ? (
          <img
            onClick={() => showImg(record)}
            src={record.image}
            width="40"
            height="30"
            border="1px solid"
            style={{ borderRadius: "3px" }}
            alt=""
          ></img>
        ) : null;
      },
    },
    {
      title: <IntlMessages id="global.weight" />,
      key: "weight",
      dataIndex: "weight",
      render: (text, record) => {
        return `${record.weight} tons`;
      },
    },

    {
      title: <IntlMessages id="global.created" />,
      key: "date",
      dataIndex: "date",
      render: (text, record) => {
        return moment(record.date).format("YYYY-MM-DD hh:mm a");
      },
    },
  ];

  return (
    <Modal
      title={<IntlMessages id="global.loads" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleCancel}
      width="70%"
    >
      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={data}
        rowKey="id"
        size="small"
        scroll={{
          x: "max-content",
        }}
        pagination={null}
      />
      {openImg ?
        <Modal
          visible={openImg}
          centered
          destroyOnClose
          footer={null}
          onCancel={closeImg}
          width={410}
        >
          <img
            src={img}
            width="400"
            height="400"
            alt="" />
        </Modal> : null
      }
    </Modal>
  );
};
export default Loads;
