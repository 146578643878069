import { Button, Table, Tag } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import driversApi from "../../api/drivers";
import IntlMessages from "../../components/IntlMessage";
import { truckSize } from "./data";
import Loads from "./Loads";
moment.tz.setDefault("US/Eastern");

const DriverModal = ({ order }) => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [driver, setDriver] = useState();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let result = await driversApi.getDriverByOrderId(order.id);
      if (result) setDrivers(result);
      setLoading(false);
    }
    fetchData();
  }, [order]);

  const openInvoices = (record) => {
    setInvoiceModal(true);
    setDriver(record);
  };
  const closeInvoice = () => {
    setInvoiceModal(false);
  };
  const columns = [
    {
      title: <IntlMessages id="global.id" />,
      key: "id",
      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.fullname" />,
      key: "driver",
      dataIndex: "driver",
      render: (text, record) => {
        return (
          <>
            <p>
              {record.driver
                ? `${record.driver.firstname} ${record.driver.lastname}`
                : null}
            </p>
            <p>{record.operator ? record.operator.title : null}</p>
          </>
        );
      },
    },
    {
      title: <IntlMessages id="global.truck" />,
      key: "car",
      dataIndex: "car",
      render: (text, record) => {
        return record.car ? truckSize[record.car.capacity] : null;
      },
    },
    {
      title: <IntlMessages id="invite.invite_status" />,
      key: "invite_status",

      dataIndex: "invite_status",
      render: (text, record) => {
        return record.invite_status === "pending" ? (
          <Tag color="orange">{record.invite_status}</Tag>
        ) : record.invite_status === "accepted" ? (
          <Tag color="success">{record.invite_status}</Tag>
        ) : (
          <Tag color="error">{record.invite_status}</Tag>
        );
      },
    },
    {
      title: <IntlMessages id="global.driver_status" />,
      key: "driver_status",

      dataIndex: "driver_status",
      render: (text, record) => {
        return record.driver_status === "pending" ?
          (
            <Tag color="orange">{record.driver_status}</Tag>
          ) : record.driver_status === "working" ? (
            <Tag color="purple">{record.driver_status}</Tag>
          ) : <Tag color="success">{record.driver_status}</Tag>;
      },
    },
    {
      title: <IntlMessages id="global.work_on" />,
      key: "work_on",

      dataIndex: "work_on",
      render: (text, record) => {
        return moment(record.work_on).format("MM-DD, hh:mm a");
      },
    },
    {
      title: <IntlMessages id="global.accepted_time" />,
      key: "updated_at",

      dataIndex: "updated_at",
      render: (text, record) => {
        return moment(record.updated_at).format("MM-DD, hh:mm a");
      },
    },
    {
      title: <IntlMessages id="global.start_at" />,
      key: "start_at",

      dataIndex: "start_at",
      render: (text, record) => {
        return record.start_at
          ? moment(record.start_at).format("MM/DD hh:mm a")
          : null;
      },
    },
    {
      title: <IntlMessages id="global.end_at" />,
      key: "end_at",

      dataIndex: "end_at",
      render: (text, record) => {
        return record.end_at
          ? moment(record.end_at).format("MM/DD hh:mm a")
          : null;
      },
    },
    {
      title: <IntlMessages id="global.loads" />,
      key: "mobile",
      dataIndex: "mobile",
      render: (text, record) => {
        return (
          <Button
            disabled={!(record.loadcount > 0)}
            type="primary"
            size="small"
            onClick={() => openInvoices(record)}
            danger
            shape="round"
          >
            {record.loadcount > 0
              ? `${record.loadcount} (${record.loadsum} tons)`
              : `${record.loadcount}`}
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={drivers}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={null}
      />
      {invoiceModal ? (
        <Loads
          orderId={order.id}
          driver={driver}
          open={invoiceModal}
          onClose={closeInvoice}
        />
      ) : null}
    </>
  );
};
export default DriverModal;
