import { PlusOutlined } from "@ant-design/icons";
import { Upload, Modal } from "antd";
import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";

UploadImage.defaultProps = {
  onChangeData: () => { },
};

function UploadImage({ onChangeData, initList }) {
  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  useEffect(() => {
    setState({ ...state, fileList: initList });
  }, [initList]);

  const handleCancel = () => {
    setState({ ...state, previewVisible: false });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const setData = async (list) => {
    let compressProcesses = list.map((item) => {
      return imageCompression(item.originFileObj, compressOptions);
    });

    let files = await Promise.all(compressProcesses);

    let imageQueries = files.map((file) => {
      return getBase64(file);
    });

    let listData = await Promise.all(imageQueries);

    return listData;
  };

  const handleChange = async (v) => {
    const { fileList, file } = v;
    setState({ fileList });
    let data = await setData(fileList);
    onChangeData(data, fileList.length ? file : null);
  };

  const { previewVisible, previewImage, fileList } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Select File</div>
    </div>
  );
  return (
    <div className="clearfix">
      <Upload
        beforeUpload={() => {
          return false;
        }}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onDownload={false}
        accept="images/*"
        multiple
      >
        {fileList.length < 1 ? uploadButton : null}
        {/* {uploadButton} */}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default UploadImage;
