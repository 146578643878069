import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Modal,
  PageHeader,
  Row,
  Tag,
} from "antd";
import IntlMessage from "../../components/IntlMessage";
import { orderType, truckSize } from "./data";
import moment from "moment-timezone";
import { Invites } from "./Invites";
import DriverModal from "./DriverModal";
import MapModal from "./MapModal";
const { Panel } = Collapse;
moment.tz.setDefault("US/Eastern");

const SubTitle = ({ data }) => {
  return (
    <>
      {data.order_status === "request" ? (
        <Tag color="orange">{data.order_status}</Tag>
      ) : data.order_status === "ongoing" ? (
        <Tag color="orange">{data.order_status}</Tag>
      ) : data.order_status === "assigned" ? (
        <Tag color="orange">{data.order_status}</Tag>
      ) : data.order_status === "finished" ? (
        <Tag color="success">{data.order_status}</Tag>
      ) : (
        <Tag color="error">{data.order_status}</Tag>
      )}
    </>
  );
};

const OrderHeader = ({ data }) => {
  return (
    <PageHeader
      title={data.product_name}
      subTitle={<SubTitle data={data} />}
      extra={[<strong key="4">{moment(data.created_at).fromNow()}</strong>]}
    />
  );
};

const Header = ({ title }) => {
  return <PageHeader title={title} />;
};

const OrderDetail = (props) => {
  const { open, onClose, order } = props;
  const [mapModal, setMapModal] = useState(false);

  const oderNo = `Job No.: ${order.order_no}`;
  let customer = order.customer ? `Customer: ${order.customer.fullname}` : null;
  let type = order.type ? `Type: ${orderType[order.type]}` : null;
  const startAt = `Start at: ${moment(order.date).format("llll")}`;
  const lodcation = `Location: ${order.from_add_text} --> ${order.to_add_text}`;
  const trucks = `Trucks: ${order.qty}`;
  let truckDetail = "Rate: ";
  let rate = null;
  if (order.type === "2" || order.type === "3") {
    let truck = JSON.parse(order.truck_size);
    Object.keys(truck).forEach((key) => {
      truckDetail = truckDetail + `${truckSize[key]}: $${truck[key]}; `;
    });
  } else rate = `Rate: $${order.price} per ton `;

  const openMap = () => {
    setMapModal(true);
  };
  const closeMap = () => {
    setMapModal(false);
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal
      title={<IntlMessage id="job_detail" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleCancel}
      width="70%"
    >
      <Collapse defaultActiveKey={['1']}>
        <Panel key="1" header={<OrderHeader data={order} />}>
          <Row justify="space-between">
            <Col span={8}>{oderNo}</Col>
            <Col span={8}>{customer ? customer : null}</Col>
            <Col span={8}>{type}</Col>
          </Row>
          <p></p>
          <Row justify="space-between">
            <Col span={8}>{startAt}</Col>
            <Col span={16}>{lodcation}</Col>
          </Row>
          <p></p>
          <Row justify="space-between">
            <Col span={8}>{trucks}</Col>
            <Col span={16}>{rate ? rate : truckDetail}</Col>
          </Row>
        </Panel>
        <Panel
          key="2"
          header={<Header title={<IntlMessage id="global.invite" />} />}
        >
          <Invites invites={order.id} />
        </Panel>
        <Panel
          key="3"
          header={<Header title={<IntlMessage id="global.drivers" />} />}
        >
          <Row justify="space-between">
            <Col></Col>
            <Col>
              <Button type="primary" onClick={openMap}>{<IntlMessage id="global.drivers_location" />}</Button>
            </Col>
          </Row>
          <DriverModal order={order} />
        </Panel>
      </Collapse>
      {mapModal ? (
        <MapModal order={order} open={mapModal} onClose={closeMap} />
      ) : null}
    </Modal>
  );
};
export default OrderDetail;
