import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import {
  Button,
  Card,
  Table,
  Tag,
  Modal,
  Row,
  Col,
} from "antd";

import PageTitle from "../../components/PageTitle";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import orderApi from "../../api/order";
import moment from "moment-timezone";
import OrderDetail from "./OrderDetail";
import { orderType, truckSize } from "./data";
moment.tz.setDefault("US/Eastern");

Orders.propTypes = {
  items: PropTypes.array,
};
Orders.defaultProps = {
  items: [],
};
function Orders() {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [modal, setModal] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "DESC",
  });

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await orderApi.getAll(filter);
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };
  const intl = useIntl();

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "DESC";
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onDelete = async (selectedRowKeys) => {
    console.log("Deleted");
    await orderApi.delete(selectedRowKeys).catch((error) => {
      console.error(error);
      Modal.error({
        title: error.data.message,
        okText: intl.formatMessage({ id: "global.close" }),
      });
      setLoading(false);
    });
    setDeleting(true);
  };

  const [order, setOrder] = useState();
  const openModal = (record) => {
    setModal(true);
    setOrder(() => record);
  };
  const closeModal = () => {
    setModal(false);
  };

  const columns = [
    {
      title: <IntlMessages id="global.id" />,
      key: "id",

      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.order_no" />,
      key: "order_no",
      width: 100,
      dataIndex: "order_no",
      render: (text, record, index) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => openModal(record)}
            danger
            shape="round"
          >
            {record.order_no}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.project_no" />,
      key: "code",

      dataIndex: "code",
      render: (text, record) => {
        return record.code;
      },
    },

    {
      title: <IntlMessages id="global.Store" />,
      key: "fullname",

      dataIndex: "fullname",
      render: (text, record) => {
        return record.customer.store.name;
      },
    },

    {
      title: <IntlMessages id="global.type" />,
      key: "type",

      dataIndex: "type",
      render: (text, record) => {
        return orderType[record.type];
      },
    },

    {
      title: <IntlMessages id="order.productName" />,
      key: "product_name",

      dataIndex: "product_name",
      render: (text, record) => {
        return record.product_name;
      },
    },

    {
      title: <IntlMessages id="order.orderStatus" />,
      key: "order_status",

      dataIndex: "order_status",
      render: (text, record) => {
        return record.order_status === "request" ? (
          <Tag color="processing">{record.order_status}</Tag>
        ) : record.order_status === "ongoing" ? (
          <Tag color="processing">{record.order_status}</Tag>
        ) : record.order_status === "assigned" ? (
          <Tag color="processing">{record.order_status}</Tag>
        ) : record.order_status === "finished" ? (
          <Tag color="success">{record.order_status}</Tag>
        ) : (
          <Tag color="error">{record.order_status}</Tag>
        );
      },
    },
    // {
    //   title: <IntlMessages id="order.payStatus" />,
    //   key: "payStatus",
    //   sorter: true,

    //   dataIndex: "payStatus",
    //   render: (text, record) => {
    //     return record.pay_status === "pending" ||
    //       record.pay_status === "confirmed" ? (
    //       <Tag color="processing">{record.pay_status}</Tag>
    //     ) : record.pay_status === "success" ? (
    //       <Tag color="success">{record.pay_status}</Tag>
    //     ) : (
    //       <Tag color="error">{record.pay_status}</Tag>
    //     );
    //   },
    // },

    {
      title: <IntlMessages id="global.trucks" />,
      key: "qty",

      dataIndex: "qty",
      render: (text, record) => {
        return `${record.qty}`;
      },
    },
    {
      title: <IntlMessages id="global.price" />,
      key: "price",

      dataIndex: "price",
      render: (text, record) => {
        let truckDetail = "";
        if (record.type === "2" || record.type === "3" || record.type === "4") {
          let truck = JSON.parse(record.truck_size);
          if (truck)
            Object.keys(truck).forEach((key) => {
              truckDetail = truckDetail + `${truckSize[key]}: $${truck[key]}; `;
            });
          truckDetail = truckDetail.substring(0, truckDetail.length - 2);
        }
        return record.price ? `$${record.price} per ton` : truckDetail;
      },
    },
    {
      title: <IntlMessages id="global.created" />,
      key: "created_at",

      dataIndex: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("MM/DD hh:mm a");
      },
    },
    {
      title: <IntlMessages id="global.work_start" />,
      key: "date",

      dataIndex: "date",
      render: (text, record) => {
        return `${moment(record.date).format("MM/DD hh:mm a")}`;
      },
    },
    {
      title: <IntlMessages id="global.expired" />,
      key: "expired",

      dataIndex: "expired",
      render: (text, record) => {
        return `${moment(record.expired).format("MM/DD hh:mm a")}`;
      },
    },
    {
      title: <IntlMessages id="global.order_end_date" />,
      key: "order_end_date",

      dataIndex: "order_end_date",
      render: (text, record) => {
        return `${moment(record.order_end_date).format("YYYY/MM/DD")}`;
      },
    },

  ];

  const dataTable = (items || []).map((i) => {
    const r = i;
    delete r.children;
    return r;
  });

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.jobs" />} />
        </Col>

        <Col>
          <ActionBar
            onDelete={() => onDelete(selectedRowKeys)}
            isShowAddButton={false}
            //onAdd={onOpenModal}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={true}
            isDisabled={!hasSelected}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={dataTable}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <OrderDetail order={order} open={modal} onClose={closeModal} />
      ) : null}
    </Card>
  );
}

export default Orders;
