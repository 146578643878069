import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter, Redirect, Switch, Route } from "react-router-dom";
import Login from "../routes/auth/Login";

import { getAuthUser } from "../redux/actions/AuthActions";
import { LoadingScreen } from "../components/LoadingScreen";

//route
import AppRoutes from "./AppRoutes";
import ResetPassword from "../routes/auth/ResetPassword";
import Signup from "../routes/auth/Signup";

function MainApp(props) {
  // state = {
  //   isLoading: true,
  // };
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    function fetchData() {
      dispatch(getAuthUser())
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const { location, match } = props;
  if (location.pathname === "/") {
    return <Redirect to={"/app/home"} />;
  }

  return (
    <React.Fragment>
      {/* <IntlProvider
                    locale={'vi'}
                > */}
      <Switch>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/resetpassword" component={ResetPassword}></Route>
        <Route exact path="/signup" component={Signup}></Route>
        <Route path={`${match.url}app`} component={AppRoutes}></Route>
      </Switch>
      {/* </IntlProvider> */}
    </React.Fragment>
  );
}

export default withRouter(MainApp);
