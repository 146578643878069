import api from '../utils/api';

class SubcribersApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/subscribers", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post("/subscribers", data);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .delete(`/subscribers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const subcribersApi = new SubcribersApi();
export default subcribersApi;