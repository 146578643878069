import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Card, Table, Modal, Row, Col } from "antd";
import PageTitle from "../../components/PageTitle";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import subcribersApi from "../../api/subcribers";

Subcribers.propTypes = {
  items: PropTypes.array,
};
Subcribers.defaultProps = {
  items: [],
};
function Subcribers(props) {

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "DESC",
  });

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await subcribersApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  console.log("item", items);
  const intl = useIntl();

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "DESC";
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onDelete = async (selectedRowKeys) => {
    console.log("Deleted");
    await subcribersApi.delete(selectedRowKeys).catch((error) => {
      console.error(error);
      Modal.error({
        title: error.data.message,
        okText: intl.formatMessage({ id: "global.close" }),
      });
      setLoading(false);
    });
    setDeleting(true);
  };

  const columns = [
    {
      title: <IntlMessages id="global.user_id" />,
      key: "user_id",

      dataIndex: "user_id",
      render: (text, record) => {
        return record.user_id;
      },
    },
    {
      title: <IntlMessages id="global.device_id" />,
      key: "device_id",

      dataIndex: "device_id",
      render: (text, record) => {
        return record.device_id;
      },
    },
    {
      title: <IntlMessages id="global.os" />,
      key: "os",

      dataIndex: "os",
      render: (text, record) => {
        return record.os ? record.os : null;
      },
    },
  ];
  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.subcribers" />} />
        </Col>

        <Col>
          <ActionBar
            onDelete={() => onDelete(selectedRowKeys)}
            isShowAddButton={false}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={true}
            isDisabled={!hasSelected}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
    </Card>
  );
}

export default Subcribers;
