import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Radio,
  Col,
  Row,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import operatorsApi from "../../api/operators";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CountryData from "../../data/countryData";
import UploadImage from "../../components/Elements/UploadImage";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function OperatorsForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [insuranceImgs, setInsuranceImgs] = useState(null);
  const [truckPhoto, setTruckPhoto] = useState(null);
  const [changeIsuranceCard, setChangeInsuranceCard] = useState(false)
  const [changeTruckPhoto, setChangeTruckPhoto] = useState(false)

  const intl = useIntl();
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        delete data.insurance_card;
        delete data.cover_image
        delete data.truck_picture
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (insuranceImgs) {
          formData.append("insurance_card", insuranceImgs);
        }
        if (!insuranceImgs && changeIsuranceCard) {
          formData.append("insurance_card", "");
        }
        if (truckPhoto) {
          formData.append("truck_picture", truckPhoto);
        }
        if (!truckPhoto && changeTruckPhoto) {
          formData.append("truck_picture", "");
        }
        if (currentData) {
          operatorsApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          operatorsApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  const fileList = useMemo(
    () =>
      currentData && currentData.insurance_card
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: currentData.insurance_card,
          },
        ]
        : [],
    [currentData]
  );

  const fileListTruckPhoto = useMemo(
    () =>
      currentData && currentData.truck_picture
        ? [
          {
            uid: "2",
            name: "image.png",
            status: "done ",
            url: currentData.truck_picture,
          },
        ]
        : [],
    [currentData]
  );

  const onChangeInsuranceImage = (v, f) => {
    setChangeInsuranceCard(true)
    setInsuranceImgs(f);
  };

  const onChangeTruckPhoto = (v, f) => {
    setChangeTruckPhoto(true)
    setTruckPhoto(f)
  }

  let initialValue = currentData
    ? {
      ...currentData,
    }
    : { type: "single", phone_code: "+1" };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.operators" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.phone" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input.Group>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="phone_code"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        placeholder="Select Store"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {CountryData.map((item, index) => {
                          let labelstr = "";
                          labelstr = `(+${item.code}) ${item.country}`;
                          return (
                            <Option key={index} value={`+${item.code}`}>
                              {labelstr}
                            </Option>
                          );
                        })}
                        ;
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.firstname" />}
              name="firstname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.lastname" />}
              name="lastname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.tax_id_no" />}
              name="tax_id_no"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.motor_carrier_no" />}
              name="motor_carrier_no"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.us_dot_no" />}
              name="us_dot_no"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.email" />}
              name="email"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label={<IntlMessages id="global.type" />} name="type">
              <Radio.Group>
                <Radio value="single">
                  <IntlMessages id="operator.single" />
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.insurance_card" />}
              name="insurance_card"

            >
              <UploadImage onChangeData={onChangeInsuranceImage} initList={fileList} />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.truck_picture" />}
              name="truck_picture"

            >
              <UploadImage onChangeData={onChangeTruckPhoto} initList={fileListTruckPhoto} />
            </Form.Item>

          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
