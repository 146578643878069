import React, { useEffect, useState } from "react";
import { Button, message, Upload, Layout, Modal, Skeleton, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ezbinConfigApi from "../../api/ezbinConfig";

const { Content } = Layout;
const { Title } = Typography;

const Contract = () => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [previewImage, setPreviewImage] = useState(null);
    const [previewTitle, setPreviewTitle] = useState('');
    const [config, setConfig] = useState(null)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const result = await ezbinConfigApi.getConfig()
            console.log("config:", result)
            setConfig(result)
            setLoading(false)
        }
        fetchData()
    }, [reload])

    let reader = new FileReader()

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);

        });

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("title", "Ezbin")
        fileList.forEach((file) => {
            formData.append("contract", file);
        });
        setUploading(true);
        if (config) {
            ezbinConfigApi
                .update(config.id, formData)
                .then(() => {
                    setFileList([]);
                    message.success("upload successfully!");
                    setReload(!reload)
                })
                .catch(() => {
                    message.error("upload failed!");
                })
                .finally(() => {
                    setUploading(false);
                });
        } else {
            ezbinConfigApi.create(formData)
                .then(() => {
                    setFileList([]);
                    message.success("upload successfully!");
                    setReload(!reload)
                })
                .catch(() => {
                    message.error("upload failed!");
                })
                .finally(() => {
                    setUploading(false);
                });
        }

    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        accept: "application/pdf",
        listType: "picture"
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }
        setPreviewImage(file);
        setOpenPreview(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));

    };

    const [openPreview, setOpenPreview] = useState(false);

    if (loading) return <Skeleton active />;
    return (
        <Content>
            <div className="db-content booking">
                <div className="my-dashboard">
                    <div className="header-my-db">
                        <Title level={4} className="title-my-db">
                            {`Ezbin Contract`}
                        </Title>
                    </div>
                    <div className="rv-tabs mb-3 contract-upload">
                        <div style={{ marginBottom: "10px" }}>
                            {config?.contract ?
                                <a
                                    href={config.contract}
                                    target="blank"
                                >
                                    {`Your Contract here`}
                                </a> : <div></div>
                            }
                        </div>

                        <Upload onPreview={handlePreview} {...props}>
                            <Button icon={<UploadOutlined />}>{`Start Upload`}</Button>
                        </Upload>
                        <Button
                            type="primary"
                            onClick={handleUpload}
                            disabled={fileList.length === 0}
                            loading={uploading}
                            style={{
                                marginTop: 16,
                            }}
                        >
                            {uploading ? "Uploading" : config?.contract ? "Update Contract" : "Start Upload"}
                        </Button>
                    </div>
                </div>
                <Modal
                    width="65%"
                    title={previewTitle} footer={null}
                    visible={openPreview}
                    onCancel={() => {
                        setOpenPreview(false);
                        setPreviewImage(null);
                        setPreviewTitle('')
                    }
                    }
                >
                    {previewImage &&
                        <embed
                            src={previewImage.preview}
                            type={previewImage.type}
                            frameBorder="0"
                            scrolling="auto"
                            width="100%"
                            height="500px"
                        >
                        </embed>}
                </Modal>
            </div>
        </Content>
    );
};

export default Contract;
