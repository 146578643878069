
import api from '../utils/api';

class DriversApi {

  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/drivers", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getLoadCount = (orderId,driverId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/loads/count/${orderId}/${driverId}`,);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDriverByOrderId = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/dinvites/order/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getByOperatorId = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/drivers/operator/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post("/drivers", data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateStatus =(id,data) =>{
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post(`/drivers/updatestatus/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .patch(`/drivers/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .delete(`/drivers/${id}`);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
});
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/drivers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const driversApi = new DriversApi();
export default driversApi;