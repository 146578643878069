import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import {
    Button,
    Card,
    Table,
    Modal,
    Row,
    Col,
    Typography,
    DatePicker,
} from "antd";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import invoicesApi from "../../api/invoices";
import FilterBar from "../../components/FilterBar";
import InvoicesItem from "../invoices/InvoicesItem";
import moment from "moment-timezone";
moment.tz.setDefault("US/Eastern");

Invoices.propTypes = {
    items: PropTypes.array,
};
Invoices.defaultProps = {
    items: [],
};
const { Text } = Typography;

function Invoices(props) {
    const { onClose, open, storeId } = props;

    const isMount = useRef(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(1);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [condition, setCondition] = useState({});
    const [itemModal, setItemModal] = useState(false);
    const [invoice, setInvoice] = useState();

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
        filter_name: ["store_id"],
        filter_value: [storeId]
    });

    const onChangeTable = async (
        pagination,
        filters,
        sorter,
        extra = { currentDataSource: [] }
    ) => {
        setFilter({
            ...filter,
            page: pagination.current,
            limit: pagination.pageSize,
            order_dir: getOrder(sorter.order),
            order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
        });
    };

    const onFilter = debounce(async (value, title, type) => {
        if (type === "search") {
            setFilter((filter) => {
                return { ...filter, keyword: value };
            });
        } else {
            const filter_obj = { ...condition, [title]: value };
            let filter_name = [];
            let filter_value = [];
            for (var key in filter_obj) {
                if (filter_obj[key]) {
                    filter_name.push(key);
                    filter_value.push(filter_obj[key]);
                }
            }
            setFilter((filter) => {
                return {
                    ...filter,
                    filter_name: filter_name,
                    filter_value: filter_value,
                };
            });
        }
    }, 300);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let items = await invoicesApi.getAll(filter);
            console.log("invoices:", items.data);

            setItems(items.data);
            setLoading(false);
            setDeleting(false);
            if (total !== items.total) setTotal(items.total);
        }
        fetchData();
    }, [filter, deleting, reload, storeId]);

    const onReload = () => {
        setReload((reload) => !reload);
    };

    const intl = useIntl();

    const getOrder = (order) => {
        if (order === "ascend") return "ASC";
        if (order === "descend") return "DESC";
        return "DESC";
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const onDelete = async (selectedRowKeys) => {
        await invoicesApi.delete(selectedRowKeys).catch((error) => {
            console.error(error);
            Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
            });
            setLoading(false);
        });
        setDeleting(true);
    };

    const openInvoicesItem = (record) => {
        setItemModal(true);
        setInvoice(record);
    };
    const closeInvoicesItem = () => {
        setItemModal(false);
    };

    const handleChangDate = (date, dateString) => {
        setFilter((f) => ({
            ...f,
            keyword: dateString,
        }));
    };

    const columns = [
        {
            title: <IntlMessages id="global.id" />,
            key: "id",
            dataIndex: "id",
            render: (text, record) => {
                return record.id;
            },
        },
        {
            title: <IntlMessages id="global.invoice_no" />,
            key: "invoice_no",
            dataIndex: "invoice_no",
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => openInvoicesItem(record)}
                        danger
                        shape="round"
                    >
                        {record.invoice_no}
                    </Button>
                );
            },
        },

        {
            title: <IntlMessages id="global.from" />,
            key: "store",
            dataIndex: "store",
            render: (text, record) => {
                return (
                    <>
                        {record.store
                            ? "Ezbin"
                            : record.operator
                                ? record.operator.title
                                : null}
                    </>
                );
            },
        },

        {
            title: <IntlMessages id="global.to" />,
            key: "invoice_no",
            dataIndex: "invoice_no",
            render: (text, record) => {
                return <>{record.store ? record.store.name : "Ezbin"}</>;
            },
        },

        {
            title: <IntlMessages id="global.job" />,
            key: "product_name",
            dataIndex: "product_name",
            render: (text, record) => {
                return (
                    <>
                        <p>{record.order.order_no}</p>
                        <Text>
                            {record.store && record.store.name
                                ? record.store.name
                                : null}
                        </Text>
                    </>
                );
            },
        },

        {
            title: <IntlMessages id="order.productName" />,
            key: "product_name",
            dataIndex: "product_name",
            render: (text, record) => {
                return record.product_name;
            },
        },

        {
            title: <IntlMessages id="global.total" />,
            key: "total",
            dataIndex: "total",
            render: (text, record) => {
                return `$${record.total}`;
            },
        },

        {
            title: <IntlMessages id="global.date" />,
            key: "date",
            dataIndex: "date",
            render: (text, record) => {
                return moment(record.date).format("MM-DD, hh:mm a");
            },
        },
    ];

    return (
        <Modal
            title="Invoices"
            visible={open}
            destroyOnClose={true}
            onCancel={() => onClose()}
            width="80%"
            onOk={() => onClose()}
        >
            <Card bordered={false} style={{ minHeight: "100%" }}>
                <Row justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col>
                    </Col>
                    <Col>
                        <ActionBar
                            onDelete={() => onDelete(selectedRowKeys)}
                            isShowAddButton={false}
                            onRefresh={() => onReload()}
                            isShowPublishButtons={false}
                            isShowCopyButton={false}
                            isShowDeleteButton={true}
                            isDisabled={!hasSelected}
                        ></ActionBar>
                    </Col>
                </Row>

                <FilterBar
                    onFilter={onFilter}
                    data={[]}
                >
                    <Col>
                        <DatePicker
                            allowClear
                            format={"YYYY-MM-DD"}
                            onChange={handleChangDate}
                        />
                    </Col>
                </FilterBar>

                <Table
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    onChange={onChangeTable}
                    rowKey="id"
                    rowSelection={rowSelection}
                    size="small"
                    loading={loading}
                    scroll={{
                        x: "max-content",
                    }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"],
                        total: total,
                        current: filter.page,
                        pageSize: filter.limit,
                    }}
                />

                {itemModal ? (
                    <InvoicesItem
                        invoice={invoice}
                        open={itemModal}
                        onClose={closeInvoicesItem}
                    ></InvoicesItem>
                ) : null}
            </Card>
        </Modal>
    );
}

export default Invoices;
