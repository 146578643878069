import api from '../utils/api';

class NotiApi {
    getAll = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await api
                    .get("/front/customer/notification");
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    };
    getNotiNotRead = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await api.get("/front/customer/notificationunread");
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    updateMarkAllAsRead = () => {
        return new Promise((resolve, reject) => {
            return api
                .post(`/front/notification/markallasread`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    updateMarkAsRead = (id) => {
        return new Promise((resolve, reject) => {
            return api
                .post(`/front/notification/markasread/${id}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    deleteNoti = (id) => {
        return new Promise((resolve, reject) => {
            return api
                .delete(`/front/notification/${id}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

}

const notificationApi = new NotiApi();
export default notificationApi;