import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Radio,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import storesApi from "../../api/stores";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import operatorsApi from "../../api/operators";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function StoreForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([])

  const intl = useIntl();
  useEffect(() => {
    async function fetchData() {
      if (isMount) {
        let result = await operatorsApi.getOperatorBelongToEzbin();
        setOperators(result.data);
      }
    }
    fetchData()
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };

        if (data.specific_operator) {
          const string = JSON.stringify(data.specific_operator)
          data.specific_operator = string
        }

        if (currentData) {
          storesApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          storesApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    name: currentData && currentData.name ? currentData.name : "",
    description:
      currentData && currentData.description ? currentData.description : "",

    email: currentData && currentData.email ? currentData.email : "",
    phone: currentData && currentData.phone ? currentData.phone : "",
    address: currentData && currentData.address ? currentData.address : "",
    code: currentData && currentData.code ? currentData.code : "",
    status: currentData && currentData.status ? 1 : 0,
    operatorIds:
      currentData && currentData.operators
        ? currentData.operators.map((data) => data.id)
        : [],
    specific_operator: currentData && currentData.specific_operator ? JSON.parse(currentData.specific_operator) : []
  };

  //console.log("initialValue", initialValue);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.Store" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.name" />}
              name="name"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.email" />}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: <IntlMessages id="global.email_not_valid" />,
                },
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            {currentData &&
              <Form.Item
                name="specific_operator"
                label={<IntlMessages id="global.specific_partner" />}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Select Operators"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {operators.map((option, index) => {
                    return (
                      <Option key={`${option.id}_${index}`} value={option.id}>
                        {option.title}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>}

            <Form.Item
              label={<IntlMessages id="global.address" />}
              name="address"

            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.project_no" />}
              name="code"

            >
              <Input />
            </Form.Item>

            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>

          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
