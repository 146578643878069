import React from 'react';
import { Drawer } from 'antd';
import IntlMessages from "../../components/IntlMessage";

function DrawerPatient(props) {
    const { onClose, visible } = props;

    return (
        <div>
            <Drawer
                width='20rem'
                title={<IntlMessages id="global.patient" />}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
            </Drawer>
        </div>
    );
}

export default DrawerPatient;