
import api from '../utils/api';

class StoresApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/stores", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getGroup = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/stores/group");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post("/stores", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .patch(`/stores/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateStatus = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .post(`/stores/changeStatus/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .delete(`/stores/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/stores/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  getStoreEmpty = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/stores/storesempty");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  getStoreOwnFleet = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/stores/storeownfleet");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const storesApi = new StoresApi();
export default storesApi;