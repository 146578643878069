import React, { useEffect, useState } from "react";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Switch } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import driversApi from "../../api/drivers"
import FilterBar from "../../components/FilterBar";
import moment from "moment-timezone";
import DriverForm from "../drivers/DriverForm";
moment.tz.setDefault("US/Eastern");

function Truckers(props) {
    const { onClose, open, operatorId } = props;

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(1);
    const [item, setItem] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [condition, setCondition] = useState({});

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
        filter_name: ["operator_id"],
        filter_value: [operatorId]
    });
    const [modal, setModal] = useState(false);

    const onChangeTable = async (
        pagination,
        filters,
        sorter,
        extra = { currentDataSource: [] }
    ) => {
        setFilter({
            ...filter,
            page: pagination.current,
            limit: pagination.pageSize,
            order_dir: getOrder(sorter.order),
            order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
        });
    };

    const onFilter = debounce(async (value, title, type) => {
        if (type === "search") {
            setFilter((filter) => {
                return { ...filter, keyword: value };
            });
        } else {
            const filter_obj = { ...condition, [title]: value };
            let filter_name = [];
            let filter_value = [];
            for (var key in filter_obj) {
                if (filter_obj[key]) {
                    filter_name.push(key);
                    filter_value.push(filter_obj[key]);
                }
            }
            setFilter((filter) => {
                return {
                    ...filter,
                    filter_name: filter_name,
                    filter_value: filter_value,
                };
            });
        }
    }, 300);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let items = await driversApi.getAll(filter);

            setItems(items.data);
            setLoading(false);
            setDeleting(false);
            if (total !== items.total) setTotal(items.total);
        }
        fetchData();
    }, [filter, deleting, reload, operatorId]);

    const onReload = () => {
        setReload((reload) => !reload);
    };

    const onOpenModal = (record = null) => {
        setModal(true);
        setItem(record);
        console.log(record);
    };

    const onCloseModal = () => {
        setModal(false);
    };
    const intl = useIntl();
    const onRemove = (data) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "global.delete_confirm" }),
            okText: intl.formatMessage({ id: "global.yes" }),
            onOk: async () => {
                await driversApi.delete(data.id).catch((error) => {
                    console.error(error);
                    Modal.error({
                        title: error.data.message,
                        okText: intl.formatMessage({ id: "global.close" }),
                    });
                    setLoading(false);
                });
                setDeleting(true);
            },
            onCancel() { },
        });
    };
    const onChangeStatus = async (ischecked, record) => {
        Modal.confirm({
            title: ischecked ? intl.formatMessage({ id: "global.activate_confirm" }) : intl.formatMessage({ id: "global.deactivate_confirm" }),
            okText: intl.formatMessage({ id: "global.yes" }),
            onOk: async () => {
                await driversApi.updateStatus(record.id, { status: ischecked }).then((res) => {
                    setItems((items) =>
                        items.map((data) => {
                            if (data.id === record.id)
                                return {
                                    ...data,
                                    status: ischecked,
                                };
                            return data;
                        })
                    );
                }).catch((err) => {
                    Modal.error({
                        title: err.data.message,
                        okText: intl.formatMessage({ id: "global.close" }),
                    });
                })
            },
            onCancel() { },
        })

    };

    const getOrder = (order) => {
        if (order === "ascend") return "ASC";
        if (order === "descend") return "DESC";
        return "DESC";
    };

    const columns = [
        {
            title: <IntlMessages id="global.id" />,
            key: "id",
            width: 20,
            sorter: true,
            dataIndex: "id",
            render: (text, record) => {
                return record.id
            },
        },
        {
            title: <IntlMessages id="global.fullname" />,
            key: "firstname",
            sorter: true,
            dataIndex: "firstname",
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onOpenModal(record)}
                        shape="round"
                    >
                        {`${record.firstname} ${record.lastname}`}
                    </Button>
                );
            },
        },

        {
            title: <IntlMessages id="global.phone" />,
            key: "mobile",
            dataIndex: "mobile",
            render: (text, record) => {
                return (
                    record.phone_code ? `(${record.phone_code}) ${record.mobile}` : record.mobile
                );
            },
        },
        {
            title: <IntlMessages id="global.status" />,
            dataIndex: "status",
            width: 100,
            key: "status",
            render: (e, record) =>
                record ? (
                    <Switch
                        checkedChildren=""
                        unCheckedChildren=""
                        checked={record.status}
                        onChange={(ischecked) => onChangeStatus(ischecked, record)}
                    />
                ) : null,
        },
        {
            title: "Delete",
            render: (text, record) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onRemove(record)}
                        danger
                        shape="round"
                        icon={<DeleteOutlined />}
                    >
                    </Button>
                );
            },
        },
    ];

    return (
        <Modal
            title="Truckers"
            visible={open}
            destroyOnClose={true}
            onCancel={() => onClose()}
            width="80%"
            onOk={() => onClose()}
        >
            <Card bordered={false} style={{ minHeight: "100%" }}>
                <Row justify="space-between">
                    <Col>
                    </Col>

                    <Col>
                        <ActionBar
                            isShowAddButton={true}
                            onAdd={onOpenModal}
                            onRefresh={() => onReload()}
                            isShowPublishButtons={false}
                            isShowCopyButton={false}
                            isShowDeleteButton={false}
                        ></ActionBar>
                    </Col>
                </Row>

                <FilterBar

                    onFilter={onFilter}
                    data={[
                    ]}

                >
                </FilterBar>

                <Table
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    onChange={onChangeTable}
                    rowKey="id"
                    size="small"
                    loading={loading}
                    scroll={{
                        x: "max-content",
                    }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"],
                        total: total,
                        current: filter.page,
                        pageSize: filter.limit,
                    }}
                />
                {modal ? (
                    <DriverForm
                        open={modal}
                        onClose={onCloseModal}
                        currentData={item}
                        onReload={onReload}
                    />
                ) : null}
            </Card>
        </Modal>
    );
}

export default Truckers;
