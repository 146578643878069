import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Col,
  Row,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CountryData from "../../data/countryData";
import driversApi from "../../api/drivers";
import operatorsApi from "../../api/operators";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function DriverForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let result = await operatorsApi.getAll(filter);
        setOperators(result.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        if (currentData) {
          driversApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          driversApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };
  let initialValue = currentData
    ? {
      ...currentData,
      operator_id: currentData.operator ? currentData.operator.id : null,
    }
    : { operator_id: null, phone_code: "+221" };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.drivers" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.phone" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input.Group>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="phone_code"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        placeholder="Select type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {CountryData.map((item, index) => {
                          let labelstr = "";
                          labelstr = `(+${item.code}) ${item.country}`;
                          return (
                            <Option key={index} value={`+${item.code}`}>
                              {labelstr}
                            </Option>
                          );
                        })}
                        ;
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.firstname" />}
              name="firstname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.lastname" />}
              name="lastname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.operators" />}
              name="operator_id"
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Operator"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {operators.map((option, index) => (
                  <Option key={option.id} value={option.id}>
                    {option.title}
                  </Option>
                ))}
                ;
              </Select>
            </Form.Item>


          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
