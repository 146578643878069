
import api from '../utils/api';

class OperatorsApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllFleet = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/fleet", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllOwner = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/owner", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOperatorsHasTruck = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/hastrucks");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getGroup = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/group");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post("/operators", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post(`/operators/updatestatus/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .patch(`/operators/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  operatorJoinStoreOrEzbin = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post(`/operators/joinezbinorcustomer/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getOperatorBelongToStore = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/operatorbelongtostore");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOperatorBelongToEzbin = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/operators/operatorbelongtoezbin");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .delete(`/operators/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/operators/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const operatorsApi = new OperatorsApi();
export default operatorsApi;