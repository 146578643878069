import Home from './home';
import Orders from './orders';
import Sites from './sites';
import Customers from './customers';
import Users from './users';
import Stores from './stores';
import Rates from './rates';
import Invite from './invite';
import Cars from './cars';
import Subcribers from './subcribers';
import Drivers from './drivers';
import Invoices from './invoices';
import Dinvites from './invite';
import Operators from './fleetOwner';
import OwnerOperators from './ownerOperator';
import Notification from './notification';
import Contract from './contract';

export const routes = [
    {
        path: 'home',
        component: Home,
        permission: null
    },

    {
        path: 'subcribers',
        component: Subcribers,
        permission: null
    },

    {
        path: 'stores',
        component: Stores,
        permission: null
    },
    {
        path: 'invite',
        component: Invite,
        permission: null
    },
    {
        path: 'notifications',
        component: Notification,
        permission: null
    },
    {
        path: 'contract',
        component: Contract,
        permission: null
    },
    {
        path: 'rates',
        component: Rates,
        permission: null
    },
    {
        path: 'orders',
        component: Orders,
        permission: null
    },
    {
        path: 'invoices',
        component: Invoices,
        permission: null
    },
    {
        path: 'customers',
        component: Customers,
        permission: null
    },
    {
        path: 'users',
        component: Users,
        permission: null
    },
    {
        path: 'dinvites',
        component: Dinvites,
        permission: null
    },
    {
        path: 'cars',
        component: Cars,
        permission: null
    },
    {
        path: 'fleetowner',
        component: Operators,
        permission: null
    },
    {
        path: 'owneroperator',
        component: OwnerOperators,
        permission: null
    },
    {
        path: 'sites',
        component: Sites,
        permission: null
    },

    {
        path: 'drivers',
        component: Drivers,
        permission: null
    },
];


