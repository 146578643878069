import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Form, Input, Button, Radio, Select, Tabs } from "antd";
import IntlMessages from "../../components/IntlMessage";

import { useIntl } from "react-intl";
import carsApi from "../../api/cars";
import UploadImage from "../../components/Elements/UploadImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import operatorsApi from "../../api/operators";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function CarForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [imgs, setImgs] = useState(null);
  const [changeImg, setChangeImg] = useState(false)
  const intl = useIntl();

  const truckSize = [
    { label: "Tri-Axle", value: "3" },
    { label: "Quad-Axle", value: "4" },
    { label: "Quint-Axle", value: "5" },
    { label: "Tandem-Axle", value: "6" },
  ];

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      var filter = {
        page: 1,
        limit: 1000,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
      };
      let operators = await operatorsApi.getAll(filter);
      setOperators(operators.data);
    }
    fetchData();

    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        delete data.cover_image;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("cover_image", imgs);
        }
        if (!imgs && changeImg) {
          formData.append("cover_image", "")
        }

        if (currentData) {
          carsApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          carsApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    onClose();
  };

  let initialValue = {
    serial: currentData && currentData.serial ? currentData.serial : "",
    capacity: currentData && currentData.capacity ? currentData.capacity : "",
    operator_id:
      currentData && currentData.operator && currentData.operator.id
        ? currentData.operator.id
        : null,
    description:
      currentData && currentData.operator ? currentData.description : "",
    cover_image:
      currentData && currentData.cover_image ? currentData.cover_image : "",
    status: currentData && currentData.status ? 1 : 0,
  };

  const fileList = useMemo(
    () =>
      currentData && currentData.cover_image
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: currentData.cover_image,
          },
        ]
        : [],
    [currentData]
  );

  const onChangeImage = (v, f) => {
    setChangeImg(true)
    setImgs(f);
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.cars" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.serial" />}
              name="serial"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.truckSize" />}
              name="capacity"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select a truck size"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {truckSize.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.value}>
                      {option.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.operators" />}
              name="operator_id"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select operator"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {operators.map((option) => (
                  <Option key={option.id} value={option.id}>{option.title}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.cover_image" />}
              name="cover_image"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessages id="global.requiredfield" />,
            //   },
            // ]}
            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
