import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Switch } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import ratesApi from "../../api/rates";
import RateForm from "./RateForm";

Rates.propTypes = {
  items: PropTypes.array,
};
Rates.defaultProps = {
  items: [],
};

function Rates(props) {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await ratesApi.getAll(filter);
      console.log(items.data);
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await ratesApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    console.log(ischecked);
    await ratesApi.update(record.id, { status: ischecked });

    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            status: ischecked,
          };
        return data;
      })
    );
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.title" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.title}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.price" />,
      key: "price",
      sorter: true,

      dataIndex: "price",
      render: (text, record) => {
        return `$${record.price} per ${record.type}`;
      },
    },

    {
      title: <IntlMessages id="global.Store" />,
      key: "store",
      sorter: true,

      dataIndex: "store",
      render: (text, record) => {
        return `${record.store.name}`;
      },
    },

    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      width: 100,
      key: "status",
      render: (e, record) => {
        return (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            defaultChecked={!!e}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        );
      },
    },

    {
      // title: <IntlMessages id="global.mobile" />,
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.Rate" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <RateForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
    </Card>
  );
}

export default Rates;
