import React, { Component } from 'react'
import { Card, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

export default class CardWithIcon extends Component {
    render() {
        var { className, link, title, description, icon, ...rest } = this.props;

        return (
            <Link to={link}>
                <Card  {...rest} hoverable>
                    <Row justify="center" style={{ fontSize: 36 }}>{icon}</Row>
                    <Row justify="center">
                        <Typography.Title level={4} >{title}</Typography.Title>

                    </Row>
                    <Row justify="center">
                        <Typography.Text type="secondary">{description}</Typography.Text>
                    </Row>
                </Card>
            </Link>
        )
    }
}
