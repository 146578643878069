import { Modal, Table } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import IntlMessages from "../../components/IntlMessage";
import loadsApi from "../../api/loads";
moment.tz.setDefault("US/Eastern");

const Loads = (props) => {
  const { orderId, driver, open, onClose } = props;
  console.log("driver", driver);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const created_at = moment(driver.work_on).format("YYYY-MM-DD")

  const filter = {
    page: 1,
    limit: 1000,
    filter_name: ["driver_id", "order_id", "created_at"],
    filter_value: [driver.driver.id, orderId, created_at],
  }
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let result = await loadsApi.getByOrderId(filter);
      setInvoices(result.data);
      setLoading(false);
    }
    fetchData();
  }, [orderId, driver, filter]);

  const handleCancel = () => {
    onClose();
  };

  const [openImg, setOpenImg] = useState(false)
  const [img, setImg] = useState("")

  const showImg = (record) => {
    setOpenImg(true)
    setImg(record.image)
  }

  const closeImg = () => {
    setOpenImg(false)
  }

  const columns = [
    {
      title: <IntlMessages id="global.ticket" />,
      key: "image",
      dataIndex: "image",
      render: (text, record) => {
        return record.image ? (
          <img
            src={record.image}
            onClick={() => showImg(record)}
            width="40"
            height="30"
            border="1px solid"
            style={{ borderRadius: "3px" }}
            alt=""
          ></img>
        ) : null;
      },
    },
    {
      title: <IntlMessages id="global.weight" />,
      key: "weight",
      dataIndex: "weight",
      render: (text, record) => {
        return `${record.weight} tons`;
      },
    },
    {
      title: <IntlMessages id="global.created" />,
      key: "date",
      dataIndex: "date",
      render: (text, record) => {
        return moment(record.date).format("MM/DD hh:mm a");
      },
    },
  ];

  return (
    <Modal
      title={<IntlMessages id="global.loads" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleCancel}
      width="70%"
    >
      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={invoices}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={null}
      />
      {openImg ?
        <Modal
          visible={openImg}
          centered
          destroyOnClose
          footer={null}
          onCancel={closeImg}
          width={410}
        >
          <img
            src={img}
            width="400"
            height="400"
            alt="" />
        </Modal> : null
      }
    </Modal>
  );
};
export default Loads;
