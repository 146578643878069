import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Row,
  Col,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import customersApi from "../../api/customers";
import moment from "moment-timezone";
import storesApi from "../../api/stores";
import CountryData from "../../data/countryData";
moment.tz.setDefault("US/Eastern");

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function CustomersForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const place_pass = "input password";
  const rolesTypes = {
    Super: "super",
    Admin: "admin",
    Driver: "driver",
    Customer: "customer",
    Agent: "agent",
  };
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let stores = await storesApi.getAll(filter);

        setStores(stores.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        if (currentData) {
          customersApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          customersApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    onClose();
  };

  let initialValue = currentData
    ? {
      ...currentData,
      password: currentData.user ? currentData.user.password : null,
      roles: currentData.user ? currentData.user.roles : null,
      store_id:
        currentData.store && currentData.store.id
          ? currentData.store.id
          : null,
    }
    : { phone_code: "+221" };

  //console.log("initialValue", initialValue);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.customers" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.lastname" />}
              name="lastname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.firstname" />}
              name="firstname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.email" />}
              name="email"
              rules={[
                {
                  type: "email",
                  message: <IntlMessages id="global.email_not_valid" />,
                },
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.roles" />}
              name="roles"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select roles"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {Object.entries(rolesTypes).map(([key, value]) => (
                  <Option key={value}>{key}</Option>
                ))}
                ;
              </Select>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.Store" />}
              name="store_id"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {stores.map((user, index) => (
                  <Option key={user.id} value={user.id}>
                    {user.email}
                  </Option>
                ))}
                ;
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.phone" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input.Group>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="phone_code"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        placeholder="Select type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {CountryData.map((item, index) => {
                          let labelstr = "";
                          labelstr = `(+${item.code}) ${item.country}`;
                          return (
                            <Option key={index} value={`+${item.code}`}>
                              {labelstr}
                            </Option>
                          );
                        })}
                        ;
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="global.requiredfield" />,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.password" />}
              name="password"
            >
              <Input.Password placeholder={place_pass} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
