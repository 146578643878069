import React from "react";
import PropTypes from "prop-types";
import { Input, Col, Row, Select } from "antd";

const { Search } = Input;
const { Option } = Select;

const FilterBar = ({
  data = [],
  textSearch = true,
  onFilter = () => { },
  searchOpition = {},
  justify = "start",
  showActionBar = true,
  colTextSearch = 6,
  children,
}) => {

  const createData = (data) => {
    if (data.length) {
      const defaultOpiton = {
        name: "name",
        col: 6,
        placeholder: "Select",
        data: [],
        type: "multiple",
      };
      return data.map(item => {
        return { ...defaultOpiton, ...item, onChange: (value) => onFilter(value, item.name, "") };
      });
    }
    return [];
  };

  const createElement = (data) => {
    var dataElement = createData(data);
    if (dataElement.length) {
      return dataElement.map((item, index) => {
        return (
          <Col sm={{ span: item.col }} xs={{ span: 24 }} key={index}>
            <Select
              placeholder={item.placeholder}
              onChange={item.onChange}
              style={{ width: '100%' }}
              allowClear={true}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {item.data.length ?
                item.data.map((option, i) => {
                  return <Option key={i} value={option.id}>{option.title ? option.title : option.name ? option.name : `${option.firstname} ${option.lastname}`}</Option>
                })
                : null
              }
            </Select>
          </Col>
        )
      });
    }
    return null;
  };

  return (
    <Row type="flex" gutter={[16, 24]} justify={justify}>
      <Col sm={{ span: colTextSearch }} xs={{ span: 24 }}>
        {textSearch ? (
          <Search
            name="search"
            className="txtSearch"
            placeholder="Search..."
            allowClear
            onChange={e => onFilter(e.target.value, e.target.name, "search")}
            style={{ width: "100%" }}
            {...searchOpition}
          />
        ) : null}
      </Col>
      {createElement(data)}
      {children}
    </Row>
  );
};

FilterBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  textSearch: PropTypes.bool,
  onFilter: PropTypes.func,
  searchOpition: PropTypes.object,
  justify: PropTypes.oneOf(["start", "end", "center", "space-between"]),
  showActionBar: PropTypes.bool,
  colTextSearch: PropTypes.number,
};

export default FilterBar;