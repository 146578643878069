import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table, Tag } from "antd";
import { materialType, truckSize } from "../../data/information";
import moment from "moment-timezone";
import inviteApi from "../../api/invite";
moment.tz.setDefault("US/Eastern");

export default function Orders(props) {
    const { onClose, open, operatorId } = props;

    const [dataTable, setDataTable] = useState([])
    const [total, setTotal] = useState(1);
    const [loadingTable, setLoadingTable] = useState(true)
    const [reload, setReload] = useState(false)

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
        filter_name: ["operator_id"],
        filter_value: [operatorId]
    });

    const onChangeTable = async (
        pagination,
        filters,
        sorter,
        extra = { currentDataSource: [] }
    ) => {
        setFilter({
            ...filter,
            page: pagination.current,
            limit: pagination.pageSize,
            order_dir: getOrder(sorter.order),
            order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
        });
    };

    const getOrder = (order) => {
        if (order === "ascend") return "ASC";
        if (order === "descend") return "DESC";
        return "DESC";
    };

    useEffect(() => {
        async function fetchData() {
            setLoadingTable(true)
            const result = await inviteApi.getAll(filter);
            setDataTable(result.data)
            setLoadingTable(false)
            if (total !== result.total) setTotal(result.total);

        }
        fetchData()
    }, [reload, operatorId, filter])

    const colums = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <Tag color="green">{record.order.order_no}</Tag>
                    </div>
                )
            }
        },
        {
            title: "Contractor",
            dataIndex: "name",
            key: "name",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span> <strong>{`Name:`}</strong> {` ${record?.order?.customer?.store?.name}`}</span>
                        <span> <strong>{`Customer:`}</strong> {` ${record?.order?.customer?.fullname}`}</span>
                        <span> <strong>{`Contact:`}</strong>{` ${record.order?.site_contract}`}</span>
                    </div>
                )
            }
        },
        {
            title: "Material",
            dataIndex: "type",
            key: "type",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }} >
                        <span>{record.order?.type === "1" ? record.order?.product_name : materialType[record?.order?.type]}</span>
                        {record.order?.ongoing ? <Tag style={{ width: "fit-content" }} color="green">{`Ongoing`}</Tag> : null}
                        {record.order.min_hours > 0 ?
                            <span><strong>{`Minimum hours:`}</strong>{` ${record.order.min_hours}`}</span>
                            : null
                        }
                    </div>
                )
            }
        },
        {
            title: "qty",
            dataIndex: "type",
            key: "type",
            align: "center",
            witdh: "10%",
            render: (text, record) => record?.order?.qty
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            align: "center",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <span><strong>{`Start:`}</strong>{` ${moment(record?.order?.date).format("MM-DD-YY hh:mm A")}`}</span>
                        <span><strong>{`Expired:`}</strong>{` ${moment(record?.order?.expired).format("MM-DD-YY hh:mm A")}`}</span>
                        <span><strong>{`End:`}</strong>{` ${moment(record?.order?.order_end_date).format("MM-DD-YY")}`}</span>
                    </div>
                )
            }
        },
        {
            title: "Rate",
            dataIndex: "price",
            key: "price",
            align: "center",
            render: (text, record) => {
                const truck = record.order?.type === "1" ? null : JSON.parse(record.order?.truck_size);
                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        {record.order?.type === "1" ?
                            <span><strong>{`$${record.order.price}/Ton`}</strong></span>
                            : Object.keys(truck).map((key) =>
                                <span key={key}><strong>{`${truckSize[key]}:`}</strong>{` $${truck[key]} `}</span>
                            )
                        }
                        {
                            record.order?.fuel_surcharge > 0 ? <span><strong>{`Fuel Surcharge:`}</strong>{` $${record.order?.fuel_surcharge}`}</span> : null
                        }
                    </div>
                )
            }
        },
        {
            title: "Location",
            dataIndex: "distance",
            key: "distance",
            align: "center",
            render: (text, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
                        <span><strong>{`From:`}</strong>{` ${record?.order?.from_add_text}`}</span>
                        <span><strong>{`To:`}</strong>{` ${record?.order?.to_add_text}`}</span>
                    </div>
                )
            }
        },
    ]

    return (
        <Modal
            title="Jobs"
            visible={open}
            destroyOnClose={true}
            onCancel={() => onClose()}
            width="80%"
            onOk={() => onClose()}
        >
            <Card bordered={false} style={{ minHeight: "100%" }}>
                <Row justify="end" >
                    <Col>
                        <Button
                            type="primary"
                            htmlType="button"
                            style={{ marginBottom: "1rem" }}
                            loading={loadingTable}
                            onClick={() => setReload(!reload)}
                        >
                            {`Refresh`}
                        </Button>
                    </Col>
                </Row>
                <Table
                    tableLayout="fixed"
                    dataSource={dataTable}
                    columns={colums}
                    onChange={onChangeTable}
                    rowKey="id"
                    size="small"
                    scroll={{ x: "max-content" }}
                    loading={loadingTable}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"],
                        total: total,
                        current: filter.page,
                        pageSize: filter.limit,
                    }}
                >
                </Table>

            </Card>
        </Modal>
    )
}