export const orderType = {
  "1": "Aggregate",
  "2": "Dirt",
  "3": "Rough",
  "4": "Asphalt"
};
export const truckSize = {
  "3": "Tri-Axle",
  "4": "Quad-Axle",
  "5": "Quint-Axle",
  "6": "Tandem-Axle",
};
