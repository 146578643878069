import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Switch } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import storesApi from "../../api/stores";
import StoreForm from "./StoreForm";
import moment from "moment-timezone";
import FilterBar from "../../components/FilterBar";
import operatorsApi from "../../api/operators";
import Customers from "./Customer";
import Invoices from "./Invoices";
moment.tz.setDefault("US/Eastern");

Stores.propTypes = {
  items: PropTypes.array,
};
Stores.defaultProps = {
  items: [],
};

function Stores(props) {
  const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});
  const [operator_ids, setOperator_ids] = useState([])
  const [isCustomerOpen, setIsCustomerOpen] = useState(false)
  const [isInvoicesOpen, setIsInvoicesOpen] = useState(false)
  const [storeId, setStoreId] = useState(null)

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "DESC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (isMount) {
        let result = await operatorsApi.getOperatorBelongToStore()
        setOperator_ids(result.data);
      }
    }
    fetchData()
    return () => {
      isMount.current = false;
    };
  }, []);


  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await storesApi.getAll(filter);
      console.log("?", items.data)
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total !== items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await storesApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    Modal.confirm({
      title: ischecked ? intl.formatMessage({ id: "global.activate_confirm" }) : intl.formatMessage({ id: "global.deactivate_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await storesApi.updateStatus(record.id, { status: ischecked });

        setItems((items) =>
          items.map((data) => {
            if (data.id === record.id)
              return {
                ...data,
                status: ischecked,
              };
            return data;
          })
        );
      },
      onCancel() { },
    })

  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "DESC";
  };

  const handleOpenCustomerModal = (id) => {
    setIsCustomerOpen(true)
    setStoreId(id)
  }

  const handleOpenInvoices = (id) => {
    setIsInvoicesOpen(true)
    setStoreId(id)
  }

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.name" />,
      key: "name",
      sorter: true,

      dataIndex: "name",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            icon={<EditOutlined />}
          >
            {record.name}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.email" />,
      key: "email",
      sorter: true,

      dataIndex: "email",
      render: (text, record) => {
        return record.email;
      },
    },

    {
      title: <IntlMessages id="global.assiged_trucking_company" />,
      key: "operator",

      dataIndex: "operator",
      render: (text, record) => {
        return record.operator ? record.operator.title : "None";
      },

    },

    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      width: 100,
      key: "status",
      render: (e, record) => {
        return (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            checked={record.status}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        );
      },
    },
    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenCustomerModal(record.id)}
          >
            {`Users`}
          </Button>
        );
      },
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenInvoices(record.id)}
          >
            {`Invoices`}
          </Button>
        );
      },
    },

    {
      // title: <IntlMessages id="global.mobile" />,
      title: "Delete",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.contractor" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh={() => onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      <FilterBar

        onFilter={onFilter}
        data={[
          {
            name: "operator",
            data: operator_ids,
            placeholder: <IntlMessages id="global.select_fleet" />,
          },

        ]}

      >
      </FilterBar>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <StoreForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
      {isCustomerOpen ?
        <Customers
          open={isCustomerOpen}
          onClose={() => setIsCustomerOpen(false)}
          storeId={storeId}
        />
        : null

      }
      {isInvoicesOpen ?
        <Invoices
          open={isInvoicesOpen}
          onClose={() => setIsInvoicesOpen(false)}
          storeId={storeId}
        />
        : null
      }
    </Card>
  );
}

export default Stores;
