import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Select,
  Tabs,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import operatorsApi from "../../api/operators";
import storesApi from "../../api/stores";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function UpdateAsignForm(props) {
  const isMount = useRef(false);
  const { onClose, idOperator, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [storesEmpty, setStoresEmpty] = useState([])
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    async function fetchData() {
      if (isMount) {
        let result = await storesApi.getStoreEmpty()
        setStoresEmpty(result.data);
      }
    }
    fetchData()
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        let asign_data = {
          join_to: data.store_id === 0 ? "ezbin" : "customer",
          store_id: data.store_id
        }
        operatorsApi
          .operatorJoinStoreOrEzbin(idOperator, asign_data)
          .then(() => {
            setLoading(false);
            handleCancel();
            props.onReload();
          })
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: error.data.message,
              okText: intl.formatMessage({ id: "global.close" }),
            });
            setLoading(false);
          });
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };



  return (
    <Modal
      title={
        (
          <IntlMessages id="global.update" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.operators" />} key="1">

            <Form.Item
              name="store_id"
              label={<IntlMessages id="global.contractor" />}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Stores"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option key="ezbin_key" value={0} >Ezbin System</Option>
                {storesEmpty.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
