import { Table, Tag } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import inviteApi from "../../api/invite";
import IntlMessages from "../../components/IntlMessage";
moment.tz.setDefault("US/Eastern");

export const Invites = ({ invites }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useState(() => {
    async function fetchData() {
      setLoading(true);
      let result = await inviteApi.getByOrderId(invites);
      setItems(result.data);
      setLoading(false);
    }
    fetchData();
  }, [invites]);
  
  const columns = [
    {
      title: <IntlMessages id="global.id" />,
      key: "id",

      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },
    {
      title: <IntlMessages id="global.operator" />,
      key: "operator.title",

      dataIndex: "operator.title",
      render: (text, record) => {
        return record.operator.title;
      },
    },
    {
      title: <IntlMessages id="invite.invite_status" />,
      key: "invite_status",

      dataIndex: "invite_status",
      render: (text, record) => {
        return record.invite_status === "pending" ? (
          <Tag color="orange">{record.invite_status}</Tag>
        ) : record.invite_status === "accepted" ? (
          <Tag color="success">{record.invite_status}</Tag>
        ) : (
          <Tag color="error">{record.invite_status}</Tag>
        );
      },
    },
    {
      title: <IntlMessages id="invite.trucks" />,
      key: "qty",

      dataIndex: "qty",
      render: (text, record) => {
        return record.qty;
      },
    },
    {
      title: <IntlMessages id="global.created" />,
      key: "created_at",

      dataIndex: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("MM/DD hh:mm a");
      },
    },

    {
      title: <IntlMessages id="global.accepted_time" />,
      key: "updated_at",

      dataIndex: "updated_at",
      render: (text, record) => {
        return moment(record.updated_at).format("MM/DD hh:mm a");
      },
    },
  ];
  return (
    <Table
      tableLayout="auto"
      columns={columns}
      dataSource={items}
      loading={loading}
      rowKey="id"
      size="small"
      scroll={{
        x: "max-content",
      }}
      pagination={false}
    />
  );
};
