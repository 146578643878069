import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Row } from 'antd';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

import { withRouter, Link } from 'react-router-dom';

// actions

const { Content, Footer, Sider } = Layout;

class AppLayout extends Component {
    componentDidMount() {
    }
    state = {
        collapsed: false,
    };


    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>


                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>

                    <Row className="logo" justify="center" >
                        <Link to="/">
                            {collapsed ?
                                <img alt='ezbin' style={{ height: 30, width: "auto" }} src={require('../assets/img/logo-icon.png')} />

                                : <img alt='ezbin' style={{ height: 40, width: "auto" }} src={require('../assets/img/logo_white.png')} />
                            }
                        </Link>
                    </Row>
                    <AppSidebar />
                </Sider>


                <Layout className="site-layout">

                    {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}

                    <AppHeader></AppHeader>
                    <Content style={{ margin: '16px 16px', overflow: 'auto' }} >

                        <div className="site-layout-background" style={{ padding: 16, minHeight: 480 }}>
                            {this.props.children}
                        </div>


                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ezbin ©2021 Created by Ezbin</Footer>
                </Layout>

            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout));