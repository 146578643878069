import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Button, Input, Card, Row, Col } from 'antd';
import { LockTwoTone, MailTwoTone } from '@ant-design/icons';
import IntlMessages from "../../components/IntlMessage";
import { login } from '../../redux/actions/AuthActions';
import { NotificationManager } from 'react-notifications';

const Login = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.auth.authUser);

    const onFinish = async (values) => {

        const dataSub = {
            email: values.email,
            password: values.password
        };

        try {
            await dispatch(login(dataSub))
        } catch (error) {
        };
    }

    const onFinishFailed = errorInfo => {
        NotificationManager.error(errorInfo.toString());
    };

    const formItemLayout = {
        labelCol: { xs: { span: 0 }, sm: { span: 0 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
    };

    if (authUser) return <Redirect to='/' />;

    return (
        <div className="fullscreen">
            <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={24} xl={8}>
                    <Card>

                        <Row type="flex" justify="center">
                            {<img src={require('../../assets/img/logo.png')} className="login-logo" />}
                            <div style={{

                            }}></div>
                        </Row>
                        {/* <Row type="flex" justify="center">

                            <Text strong>Easy travel</Text>
                        </Row> */}
                        <p></p>
                        <Form
                            {...formItemLayout}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            size="large"
                        >
                            <Form.Item
                                name="email"
                                rules={[

                                    {
                                        type: 'email',
                                        message: <IntlMessages id="global.email_not_valid" />,
                                    },
                                    {
                                        required: true,
                                        message: <IntlMessages id="global.email_required" />,
                                    },
                                ]}
                            >
                                <Input prefix={<MailTwoTone className="site-form-item-icon" />}

                                    placeholder="Email"
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[


                                    {
                                        required: true,
                                        message: <IntlMessages id="global.password_required" />,
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockTwoTone className="site-form-item-icon" />} placeholder="Password" />
                            </Form.Item>

                            <Row justify="space-between">
                                <Col></Col>
                                <Col><Link to='/resetpassword'><IntlMessages id="global.forgot_password" /></Link></Col>
                            </Row>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    <IntlMessages id="global.signin" />
                                </Button>
                            </Form.Item>


                            {/* <Form.Item>

                                <Link to='/signup'>
                                    <Button
                                        type="secondary"
                                        htmlType="button"
                                        className="login-form-button"
                                    >
                                        <IntlMessages id="global.signup" />
                                    </Button>

                                </Link>


                            </Form.Item> */}


                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default Login;