
import api from '../utils/api';

class LoadsApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/loads", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getByOrderId = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/loads/driver/day`,{ params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post("/loads", data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .patch(`/loads/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .delete(`/loads/${id}`);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
});
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/loads/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

}
const loadsApi = new LoadsApi();
export default loadsApi;