import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';

import menu from '../menu';
import MenuGroupItem from '../components/Menu/MenuGroupItem';
import MenuItem from '../components/Menu/MenuItem';

const AppSidebar = ({ history, location }) => {

    const onClickMenu = (key) => {
        history.push(key);
    };

    const { pathname } = location;

    return (
        <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[pathname]}
            style={{ borderRight: 0 }}
            onClick={({ item, key, keyPath }) => onClickMenu(key)}
        >
            {
                menu.map((item, key) => {
                    if (item.children) {
                        return <MenuGroupItem item={item} key={key} />
                    } else {
                        return <MenuItem item={item} key={item.path} />
                    }
                })
            }
        </Menu>
    );
};

export default withRouter(AppSidebar);